/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LiveDTO } from '@app/modules/live/dtos/live';

import { environment } from '@env/environment';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TranslateService } from '@ngx-translate/core';
import * as L from 'leaflet';
import { NGXLogger } from 'ngx-logger';

import {
    BehaviorSubject, Observable, ReplaySubject,
    Subject
} from 'rxjs';
import {
    catchError,
    finalize,
    map,
    retry,
    take,
    tap
} from 'rxjs/operators';
import { PhotoRelation, PhotoSize, RecordState } from '../constants/global-enum';

import { IPostResponse } from '../interfaces/IPostResponse';
import { IPagination } from '../interfaces/pagination.interface';
import { ParameterType } from '../interfaces/parametertype';
import { ISubscribeObject } from '../interfaces/subscribe-object.interface';
import { SubSink } from '../subsink';

import { formatDate } from '@angular/common';
import { BaseService } from './base-service.service';

import { CustomEventService } from './custom-event.service';
import { GlobalVariablesService } from './global-variables.service';
import { SnackBarService } from './snack-bar.service';

@Injectable({ providedIn: 'root' })
export class DataService extends BaseService {
    public configurable: any;

    public globalSearchTerms: Subject<string> = new Subject();
    public broadcastSearchTerms = this.globalSearchTerms.asObservable();

    public globalMapTagData: BehaviorSubject<any> = new BehaviorSubject(null);
    public mapTagData = this.globalMapTagData.asObservable();

    public globalWiegandgData: BehaviorSubject<any> = new BehaviorSubject(null);
    public wiegandTagData = this.globalWiegandgData.asObservable();

    public _globalData: BehaviorSubject<null> = new BehaviorSubject(null);
    public _globalDailySearchModel: BehaviorSubject<null> = new BehaviorSubject(null);

    public isMapDragged: Subject<null> = new Subject();

    public isSystemMenuInitialise: BehaviorSubject<any> = new BehaviorSubject(false);

    public _objectRelations: BehaviorSubject<ISubscribeObject> = new BehaviorSubject(null);

    public _objectItemTypeId: BehaviorSubject<null> = new BehaviorSubject(null);
    public _activityTypeId: BehaviorSubject<null> = new BehaviorSubject(null);

    public tagItemPropertiesSearchPanel: BehaviorSubject<null> = new BehaviorSubject(null);
    public dailyMovementSearchPanel: BehaviorSubject<null> = new BehaviorSubject(null);

   
    public soundConfig: Subject<boolean> = new Subject();

    public tagHistoryFilter: any;
    

    private headers = new HttpHeaders();

    private subs = new SubSink();

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        private _fuseConfirmationService: FuseConfirmationService,
        private customEventService: CustomEventService,
        private snackbar: MatSnackBar,
        private snackbarService: SnackBarService,
        private global: GlobalVariablesService,
        private logger: NGXLogger,
        private globals: GlobalVariablesService

    ) {
        super();
        
        this.headers = new HttpHeaders().append('Content-Type', 'application/json; charset=utf-8; multipart/form-data;');

    }


    public getParametersByItem(item: ParameterType | string): any {
        const currentlang: any = this.translateService.currentLang || 'tr';
        return this.http.get(`${'./assets/languages/shared'}/${currentlang}${'.json'}`).pipe(
            map((res: any) => res[item])
        );
    }

    public getTutorialData(item: any): Observable<any> {
        return this.http.get(`${'./assets/languages/i18n'}/${this.translateService.currentLang}` + '.json')
            .pipe(
                map((res: any) => (res.TUTORIAL[item]))
            );
    }

    // public getBuildingSvg(): any {
    //     const url = `${this.getBaseUrl()}/assets/images/svg/${this.global.applicationSettings.tenantId}.svg`;
    //     return this.http.get(url);
    // }

    public getFloorTile(floorId: string, extension: string = undefined): any {
        const url = `${environment.tile}/tiles/${floorId}/{z}/{x}/{y}.png`;
        return url;
    }

    public buildTileUrl(floorId: string, extension: LiveDTO.TileExtensions): any {
        let url = `${environment.tile}/tiles/${floorId}/{z}/{x}/{y}`;

        switch (extension) {
            case LiveDTO.TileExtensions.Png:
                url += '.png';
                break;

            case LiveDTO.TileExtensions.Jpeg:
                url += '.jpg';
                break;

            default:
                break;
        }

        return url;
    }


    public getBuildingFloorTile(floorId: string, extension: LiveDTO.TileExtensions): any {
        const url = this.buildTileUrl(floorId, extension);
        return this.http.get(url);
    }

    public buildingMainTile(): any {
        const url = `${environment.tile}/tiles/main_tile.jpg`;
        return this.http.get(url);
    }

    public getBaseUrl(): string {
        return `${location.protocol}//${(location.port ? location.hostname + ':' + location.port : location.hostname)}`;
    }

    public getMapCenter(): any {
        return `${environment.mapCenter}`;
    }

    public getTranslated(terms: string): string {
        let translateResults: any;
        this.translateService.get(terms).pipe(take(1)).subscribe((results: string) => {
            translateResults = results;
            return results;
        });
        return translateResults;
    }

    public get<T>(service: string, normalizeUrl = true): Observable<T> {
        let url = `${this.globals.apiAddress}/${service}`;
        if (normalizeUrl) { url = this.normalizeUrl(url); }

        return this.http.get<T>(url).pipe(
            catchError((error: any) => this.handleError(error))
        );
    }

    public getData<T>(service: string, searchModel?: IPagination): any {
        let url = `${this.globals.apiAddress}/${service}`;
        url = this.normalizeUrl(url);
        return this.http.post<T>
            (
                url, { params: searchModel }
            ).pipe(retry(3),
                catchError(this.handleError),
                tap((res) => {
                })
            );
    }

    public getAny<T>(service: string): any {
        let url = `${this.globals.apiAddress}/${service}`;
        url = this.normalizeUrl(url);
        return this.http.get<T>(url);
    }

    public getAll<T>(service: string, search: any): any {
        let url = `${this.globals.apiAddress}/${service}`;
        url = this.normalizeUrl(url);
        return this.http.post<T>(url, search, { headers: this.headers });
    }

    public getById<T>(service: string, id: string): Observable<T> {
        let url = `${this.globals.apiAddress}/${service}/${id}`;
        url = this.normalizeUrl(url);
        return this.http.get<T>(url).pipe(
            catchError((error: any) => this.handleError(error))
        );
    }

    public post<T>(service: string, data?: any): any {
        let url = `${this.globals.apiAddress}/${service}`;
        url = this.normalizeUrl(url);
        return this.http.post<T>(url, data, { headers: this.headers }).pipe(
            catchError((error: any) => this.handleError(error))
        );
    }

    public put<T>(service: string, data?: any, params?: any): Observable<T> {
        let url = `${this.globals.apiAddress}/${service}`;
        url = this.normalizeUrl(url);
    

        return this.http.put<T>(url, data, { headers: this.headers }).pipe(
            catchError((error: any) => this.handleError(error))
        );
    }

    public getParentsList<T>(searchTerms: string): Observable<T> {

        const model: any = {
            searchTerms: searchTerms,
            pageIndex: 1,
            pageSize: 20,
        };
        return this.http.post<T>(`${this.globals.apiAddress}/object-safety/get-all-parents`, model, { headers: this.headers });
    }

    public fecthDataAsync<T>(service: string, searchTerms: any): Observable<T> {
        let url: string = `${this.globals.apiAddress}/${service}`;
        url = this.normalizeUrl(url);
        return this.http.post<T>(url, searchTerms, { headers: this.headers });
    }

    public delete<T>(service: string): Observable<T> {
        return this.http.delete<T>(`${this.globals.apiAddress}/${service}`, { headers: this.headers }).pipe(
            catchError((error: any) => this.handleError(error))
        );
    }

    public deleteWithUndo(service: string, action?: string): boolean {
        let result = false;
        this.snackbar
            .open(
                this.getTranslated('SYSTEM.RECORD.IS_DELETING'),
                this.getTranslated('SYSTEM.RECORD.CANCEL'),
                {
                    duration: 5000,
                    panelClass: ['red-snackbar', 'err-snackbar'],
                },
            )
            .afterDismissed()
            .subscribe((info) => {
                if (info.dismissedByAction === false) {

                    this.delete(service).subscribe(
                        {
                            next: (res: IPostResponse) => {

                                if (res.success) {

                                    this.snackbarService.openSuccessSnackBar(
                                        this.getTranslated('SYSTEM.ACTION.DEL_SUCCESS')
                                    );

                                    if (action) {
                                        this.customEventService.publish(action);
                                    }
                                    result = true;

                                } else {

                                    this.snackbarService.openErrorSnackBar(this.getTranslated(res.messageCode));
                                    result = false;
                                }
                            },
                            error: (err: any) => {
                                this.snackbarService.openErrorSnackBar(err.message);
                                result = false;
                            },
                            complete: () => { }
                        }
                    );
                }
            });

        return result;
    }

    public deleteSelectedRecord(service: string, action?: string, confirmLabel?: string): void {
        service = this.normalizeUrl(service);
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
            title: this.getTranslated('SYSTEM.RECORD.IS_DELETING'),
            message: this.getTranslated('SYSTEM.RECORD.DELETE_COFIRM'), //  'Are you sure you want to DELETE this Record ?',
            actions: {
                confirm: {
                    label: confirmLabel
                }
            }
        });

        confirmation.afterClosed().subscribe((result) => {
            // If the confirm button pressed...
            if (result === 'confirmed') {
                this.delete(service)
                    .pipe(
                        finalize(() => {

                        })
                    )
                    .subscribe(
                        {
                            next: (res: any) => {
                                this.snackbarService.openSuccessSnackBar(
                                    this.getTranslated('SYSTEM.ACTION.DEL_SUCCESS')
                                );
                                if (action) {
                                    this.customEventService.publish(action);
                                }
                                result = true;
                            },
                            error: (err: any) => {
                                this.snackbarService.openErrorSnackBar(err.message);
                                console.log(err.message);
                                result = false;
                            },
                            complete: () => { }
                        });
            }
        });
    }

    public changeRecordStateWithUndo(message: string, service: string, item: any): void {
        this.snackbar.open(
            message,
            this.getTranslated('SYSTEM.RECORD.CANEL'),
            { duration: 4000 }
        ).afterDismissed().subscribe((info) => {

            if (info.dismissedByAction === false) {
                this.updateRecordState(item, service);
            }

        });
    }

    private updateRecordState(item: any, url: string, state: boolean = true): void {
        const service = `${url}/${item.id}`;

        url = this.normalizeUrl(url);

        this.subs.add(
            this.put(service).subscribe((result: any) => {
                if (result.success) {
                    if (state) {
                        // pls do something, what can i do sometimes?
                    }
                    this.customEventService.publish('record-state-updated');
                }
            })
        );
    }

    public getStatus(recordState: number): string {
        if (recordState === RecordState.Passive) {
            return this.getTranslated('SYSTEM.RECORDS.STATE.PASIVE');
        } else if (recordState === RecordState.Active) {
            return this.getTranslated('SYSTEM.RECORDS.STATE.ACTIVE');
        } else if (recordState === RecordState.Deleted) {
            return this.getTranslated('SYSTEM.RECORDS.STATE.DELETED');
        }
    }

    public photoLink(photoId: string, relation: PhotoRelation, photoSize: PhotoSize = PhotoSize.Medium, tenantId: string = "acibadem"): string {
      
        // const relation: any = PhotoRelation[PhotoRelation.TrackItem];

         let url = `${this.global.photoServerAddress}/photos/${tenantId}/${relation}/${photoSize}_${photoId}`; //add extension later

        if (photoSize === PhotoSize.Original) {
            url = `${this.global.photoServerAddress}/photos/${tenantId}/${relation}/${photoId}`;
        } //add extension later

         return url;
    }

    public getLocationInfo(latlng: L.LatLng): Observable<any> {

        return this.http.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latlng.lat}&lon=${latlng.lng}`);
    }

    public getDataFromCache<T>(url: string, forceRefresh: boolean = false): any {
        url = this.normalizeUrl(url);

        return this.callAndCache(url);

    }

    public getAvatarPhoto(id: any): any {
        return `${environment.tile}/avatar/${id}.jpg`;
    }

    // public photoGallery(model?: any): any {
    //     const url = `${this.global.navizardSettings.photoIp}/tps-photo/get-by-photo-relations`;
    //     return this.http.post(url, model, { headers: this.headers });
    // }

    public downloadExcel(service: string, model: any): void {
        let queryParams = new HttpParams();
        const propNames = Object.getOwnPropertyNames(model);
        propNames.forEach(
            (propName) => {
                const propValue = model[propName];
                if (propValue) {
                    if (typeof propValue === 'object' && propValue instanceof Date) {
                        // If the property is a Date object, format it and append to query params
                        const formattedDate = formatDate(propValue, 'MM/dd/yyyy', 'en-US');
                        queryParams = queryParams.append(propName, formattedDate);
                    } else {
                        // For other property types, just append to query params
                        queryParams = queryParams.append(propName, propValue);
                    }
                }
            }
        );

        let url: any = `${this.globals.apiAddress}/${service}?${queryParams.toString()}`;
        console.log(url);

        window.open(url);
    }

    public downLoadFile(data: any, type: string): void {
        const blob = new Blob([data], { type: type });
        let url = window.URL.createObjectURL(blob);
        url = this.normalizeUrl(url);
        const pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
        }
    }

    public diffDateTime(expiryTime: Date, currentDate: Date): any {
        const _expiryTime = new Date(expiryTime);
        const _currentDate = new Date(currentDate);
        const diffSecond: any = (_expiryTime.getTime() - _currentDate.getTime()) - 180000;

        localStorage.setItem('diffSecond', diffSecond);

        return diffSecond;
    }

    private callAndCache<T>(service: string): any {
        let url = `${this.globals.apiAddress}/${service}`;
        url = this.normalizeUrl(url);
        return this.http.get<T>(url).pipe(
            tap((res) => {

            })
        );
    }

    // Trying Diff approach
    // BUILD URL WITH QUERY PARAMS
    /* private createUrlWithQueryParameters(action: string, queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
     ): string {
         const urlBuilder: UrlBuilder = new UrlBuilder(this.api, action);
         if (queryStringHandler) {
             queryStringHandler(urlBuilder.queryString);
         }

         let url = urlBuilder.toString();
         url = this.ikiAciliAdana(url);
         return url;
     }

     // BUILD URL WITH PATH VARIABLES
     private createUrlWithPathVariables(action: string, pathVariables: any[] = []): string {
         let encodedPathVariablesUrl: string = '';
         for (const pathVariable of pathVariables) {
             if (pathVariable !== null) {
                 encodedPathVariablesUrl += `/${encodeURIComponent(pathVariable.toString())}`;
             }
         }
         const urlBuilder: UrlBuilder = new UrlBuilder(this.api, `${action}${encodedPathVariablesUrl}`);
         let url = urlBuilder.toString();
         url = this.ikiAciliAdana(url);
         return url;
     }*/

    // Convert cases to kebab-case. eg. trackTypes -> track-types, TrackTypes -> track-types, tracktypes -> allahu-ekber
    // note that: this func is temporary
    public normalizeUrl(text: string): string {

        if (/[A-Z]/.test(text)) {
            this.logger.error(`Please replace this url ${text}`);
        }

        return text.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, m) => (m ? '-' : '') + $.toLowerCase());
    }

    private loadLocalSystemMenu(tenantId: string): Observable<any> {
        return this.http.get(`${this.getBaseUrl()}/assets/menu/menu.${tenantId}.json`);
    }

}
