import { Component, Input, OnInit } from '@angular/core';
import { BadisOauthLoginService } from '@app/core/services/auth/badis-oauth-login.service';
import { DataService } from '@app/core/services/data.service';
import { GlobalVariablesService } from '@app/core/services/global-variables.service';
import { environment } from '@env/environment';


@Component({
    selector: 'app-navizard-versioning',
    templateUrl: './navizard-versioning.component.html',
    styleUrls: ['./navizard-versioning.component.scss']
})
export class NavizardVersioningComponent implements OnInit {
    public navizardAppVersion: string;
    public uiVersion: string = '1.0.0';
    public liveTrackerVersion: string;

    constructor(
        public auth: BadisOauthLoginService,
        public dataService: DataService,
        public global: GlobalVariablesService
    ) {
    }

    ngOnInit(): void {
        
        this.uiVersion = environment.version;
        this.navizardAppVersion = this.global.app.version;
        this.liveTrackerVersion = this.global.tracker.version;
    }
}
