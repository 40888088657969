import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

@Injectable()
export class FetchJsonFileService {

    constructor(private http: HttpClient) { }

    public fetchJsonFile(url: string): Observable<any> {

        return this.http.get(url)
            .pipe(
                delay(100),
                map((res: any) => res),
            );
    }

}


