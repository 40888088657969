import { OnDestroy, Pipe, PipeTransform } from '@angular/core';;
import { TranslateService } from '@ngx-translate/core';
import { appInjector as appInjector } from '@app/shared/classes/app-injector';
@Pipe({
  name: 'durationsAgo',
  pure: false,
})
export class TimeDurationsPipe implements PipeTransform, OnDestroy {
  public translateService: TranslateService;
  
  private formattedDays: string = '';
  private formattedHours: string = '';
  private formattedMinutes: string = '';
  private formattedSeconds: string = '';
  
  constructor() {
    this.translateService = appInjector.get(TranslateService);
  }

  transform(value: any): any {
    
    this.formattedDays = '';
    this.formattedHours = '';
    this.formattedMinutes = '';
    this.formattedSeconds = '';

    const dateObj: any = new Date(value * 1000);

    const days = Math.floor(value / (3600 * 24));
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getUTCSeconds();

    if (days > 0) {
      this.formattedDays = `${days}${this.translateService.instant('DURATION.DAYS')} `;
    }

    if (hours > 0) {
      this.formattedHours = `${hours}${this.translateService.instant('DURATION.HOURS')}`;
    }

    if (minutes > 0) {
      this.formattedMinutes = `${minutes}${this.translateService.instant('DURATION.MINUTES')}`;
    }

    if (seconds >= 0) {
      this.formattedSeconds = `${seconds}${this.translateService.instant('DURATION.SECONDS')}`;
    }

    let timeArr = [this.formattedDays, this.formattedHours, this.formattedMinutes, this.formattedSeconds];
    timeArr = timeArr.filter(val => val !== '');
    return timeArr.join(' ');

  }


  ngOnDestroy(): void { }
}
