import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';

import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';

import { DataService } from '@app/core/services/data.service';
import { BadisOauthLoginService } from '@app/core/services/auth/badis-oauth-login.service';
import { UserAccessToken } from '@app/core/services/auth/model/auth-user.interface';
import { NetworkStatusService } from '@app/shared/services/network-status.service';
import { NotificationService } from '@app/pages/notifications/services/notification.service';
import { BackgroundService } from '@app/shared/services/background.service';
import { GlobalVariablesService } from '@app/core/services/global-variables.service';
@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    styleUrls: ['./classy.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

    scrollbarVisibilityBugFix: boolean = false;

    isScreenSmall: boolean;
    navigation: Navigation;
    public user: UserAccessToken;

    public displayName: string;
    public userName: string;
    public avatar: any;

    private _unsubscribeAll: Subject<any> = new Subject<any>();


    notificationData$ = this.notificationService.getNotificationsData();
 
    constructor(
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _badisOauthLoginService: BadisOauthLoginService,
        private dataService: DataService,
        public networkStatusService: NetworkStatusService,
        private notificationService: NotificationService,
        public backgroundService: BackgroundService,
        public globals: GlobalVariablesService
    ) {

        this._badisOauthLoginService.onAuthStatusChanged.subscribe((isAuthenticated: boolean) => {

            if (isAuthenticated) {

                const authUser = this._badisOauthLoginService.userAccessToken;
                this.displayName = authUser ? authUser.displayName : 'Navizard';
                this.avatar = authUser.avatar;
                this.userName = authUser.userName;
            }

        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.scrollbarVisibilityBugFix = true;
        }, 100);
    }

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        // this._userService.user$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((user: User) => {
        //         this.user = user;
        //     });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    public loadActiveTagsFilter(): void {

    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }

    }

    public photoLink(photoId: string): any {
        return this.dataService.getAvatarPhoto(photoId);
    }

    public errorHandler(event: any): any {
        event.target.src = './assets/images/logo/navizard.png';
    }

    public backErrorHandler(event: any): any {
        event.target.src = './assets/images/logo/navizard.png';
    }


}
