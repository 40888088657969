import { Injectable } from '@angular/core';
import { appInjector as appInjector } from '@app/shared/classes/app-injector';
import { NGXLogger } from 'ngx-logger';
import { v4 as uuidv4 } from 'uuid';
@Injectable({
  providedIn: 'root'
})
export class CommonBaseService {
  public log: NGXLogger;
  public id: string = uuidv4();
  constructor() {
    this.log = appInjector.get(NGXLogger);
  }
}
