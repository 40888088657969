export enum LocationUpdateStatus {
    None = 0,
    InitialZone = 1,
    ZoneChanged = 2,
    StillAtZone = 3,
    ZoneChangedWithTimeout = 4
}

export class TagLocationModel {
    
    accessHistoryId?: string;
    tagSessionHistoryId: string;
    previousZoneId?: string;
    currentZoneId: string;
    currentReaderAntennaId: string;
    longitude: string;
    latitude: string;
    zoneDuration: number;
    zoneChangeDuration: number;
    distance: number;
    firstSeen: Date;
    lastSeen: Date;
    locationUpdateStatus: LocationUpdateStatus;
    
    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
            this.firstSeen = new Date(data.firstSeen);
            this.lastSeen = new Date(data.lastSeen);
        }
    }
}
