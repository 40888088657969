import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, Subject, timer, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScreenLockerService {

    private noJob$: Observable<any>;
    private timer$: Subscription;
    private timeOutMilliSeconds: number;
    private idleSubscription: Subscription;

    public expired$: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    public startWatching(timeOutSeconds: number): Observable<any> {

        this.noJob$ = merge(
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'click'),
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'keypress'),
            fromEvent(document, 'DOMMouseScroll'),
            fromEvent(document, 'mousewheel'),
            fromEvent(document, 'touchmove'),
            fromEvent(document, 'MSPointerMove'),
            fromEvent(window, 'mousemove'),
            fromEvent(window, 'resize'),
        );

        this.timeOutMilliSeconds = timeOutSeconds * 1000;

        this.idleSubscription = this.noJob$.subscribe((res) => {
            this.resetTimer();
        });

        this.startTimer();

        return this.expired$;
    }

    private startTimer(): void {
        this.timer$ = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe((res) => {
            this.expired$.next(true);
        });
    }

    public resetTimer(): void {
        this.timer$.unsubscribe();
        this.startTimer();
    }

    public stopTimer(): void {
        this.timer$.unsubscribe();
        this.idleSubscription.unsubscribe();
    }

}
