import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpEventType } from '@angular/common/http';
import { lastValueFrom, Observable, throwError, Subject } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { PaginationBase } from './models/pagination-base';
import { appInjector } from '../classes/app-injector';
import { RequestBase, SimpleResponseModel } from './models/request-base';
import CustomStore from 'devextreme/data/custom_store';
import { OperationResult, OperationType } from './models/operation-result';
import { IBaseHttpService } from './models/ibase-http-service';
import { Guid } from '@app/core/constants/constants';
import { EventEmitter } from '@angular/core';

export class BaseHttpService<T extends RequestBase> implements IBaseHttpService<T> {
  public operationCompleted: EventEmitter<OperationResult<T>> = new EventEmitter();
  public uploadProgress: EventEmitter<number> = new EventEmitter();

  public httpClient: HttpClient;

  constructor(
    public url: string,
    public endpoint: string,
  ) {
    this.httpClient = appInjector.get(HttpClient);
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  public getWithPagination(page: number, pageSize: number, filter: string): Observable<PaginationBase<T>> {
    const params = new HttpParams()
      .set('page', page)
      .set('pageSize', pageSize)
      .set('filter', filter ?? '')
      .set('sort', 'name asc');
    return this.httpClient
      .get<PaginationBase<T>>(`${this.url}/${this.endpoint}`, { params })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  private isNotEmpty(value: any): boolean {
    return value !== undefined && value !== null && value !== '';
  }

  public getDataSource(idColumnName: string = "id", userData: any = null): CustomStore {
    return new CustomStore({
      key: idColumnName,

      load: (loadOptions: any): any => {
        loadOptions.userData = userData;
        let params: HttpParams = new HttpParams();
        [
          'skip',
          'take',
          'requireTotalCount',
          'requireGroupCount',
          'sort',
          'filter',
          'totalSummary',
          'group',
          'groupSummary',
          'userData',
        ].forEach((item) => {
          if (item in loadOptions && this.isNotEmpty(loadOptions[item])) {
            params = params.set(item, JSON.stringify(loadOptions[item]));
          }
        });

        return lastValueFrom(this.httpClient.get(`${this.url}/${this.endpoint}/data-source`, { params }))
          .then((data: any) => ({
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount,
          }))
          .catch(this.handleError);
      },
    });
  }

  public getSimple<U>(): Observable<U> {
    return this.httpClient
      .get<U>(`${this.url}/${this.endpoint}/simple`)
      .pipe(
        
        catchError(this.handleError)
      );
  }

  public getLookUpCache<U>(): Observable<U> {
    return this.httpClient
      .get<U>(`${this.url}/${this.endpoint}/lookup-cache`)
      .pipe(
        
        catchError(this.handleError)
      );
  }

  public fetchSummary<U>(id: string | Guid): Observable<U> {
    return this.httpClient
      .get<U>(`${this.url}/${this.endpoint}/${id}/summary`)
      .pipe(
        
        catchError(this.handleError)
      );
  }

  public getById(id: string | Guid): Observable<T> {
    return this.httpClient
      .get<T>(`${this.url}/${this.endpoint}/${id}`)
      .pipe(
        
        catchError(this.handleError)
      );
  }

  public getEditModelById<U>(id: string | Guid): Observable<U> {
    return this.httpClient
      .get<U>(`${this.url}/${this.endpoint}/${id}/edit`)
      .pipe(
        
        catchError(this.handleError)
      );
  }

  public create(item: T, options?: { multipart: boolean }): Observable<T> {
    if (this.hasFileUpload(item) || options?.multipart) {
      const formData = this.buildFormData(item);
      return this.httpClient.post<T>(`${this.url}/${this.endpoint}`, formData, {
        reportProgress: true,
        observe: 'events'
      }).pipe(
        map(event => this.handleFileUploadEvent(event, item, OperationType.create)),
        
        catchError(this.handleError)
      );
    } else {
      return this.httpClient.post<T>(`${this.url}/${this.endpoint}`, JSON.stringify(item), this.httpOptions)
        .pipe(
          map((data) => this.handleSuccess(data, item, OperationType.create)),
          retry(1),
          catchError(this.handleError)
        );
    }
  }

  public update(item: T, options?: { multipart: boolean }): Observable<T> {
    if (this.hasFileUpload(item) || options?.multipart) {
      const formData = this.buildFormData(item);
      return this.httpClient.put<T>(`${this.url}/${this.endpoint}/${item.id}`, formData, {
        reportProgress: true,
        observe: 'events'
      }).pipe(
        map(event => this.handleFileUploadEvent(event, item, OperationType.update)),
        
        catchError(this.handleError)
      );
    } else {
      return this.httpClient.put<T>(`${this.url}/${this.endpoint}/${item.id}`, JSON.stringify(item), this.httpOptions)
        .pipe(
          map((data) => this.handleSuccess(data, item, OperationType.update)),
          retry(1),
          catchError(this.handleError)
        );
    }
  }

  public delete(item: T): Observable<T> {
    return this.httpClient.delete<T>(`${this.url}/${this.endpoint}/${item.id}`, this.httpOptions)
      .pipe(
        map((data) => this.handleSuccess(data, item, OperationType.delete)),
        
        catchError(this.handleError)
      );
  }

  private handleSuccess(data: any, item: T, operationType: OperationType): T {
    const operationResult = new OperationResult<T>();
    operationResult.model = item;
    operationResult.operationType = operationType;
    operationResult.success = true;
    this.operationCompleted.emit(operationResult);
    return data;
  }

  private handleFileUploadEvent(event: any, item: T, operationType: OperationType): T {
    if (event.type === HttpEventType.UploadProgress) {
      if (event.total) {
        const progress = Math.round((100 * event.loaded) / event.total);
        this.uploadProgress.emit(progress);
      }
    } else if (event.type === HttpEventType.Response) {
      const operationResult = new OperationResult<T>();
      operationResult.model = item;
      operationResult.operationType = operationType;
      operationResult.success = true;
      this.operationCompleted.emit(operationResult);
      return event.body as T;
    }
    return null;
  }

  private hasFileUpload(item: T): boolean {
    return Object.values(item).some(value => value instanceof File);
  }

  private buildFormData(item: T): FormData {
    const formData = new FormData();
    Object.keys(item).forEach(key => {
      const value = item[key];
      if (value instanceof File) {
        formData.append(key, value);
      } else {
        formData.append(key, value as string);
      }
    });
    return formData;
  }

  public handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = ` ${error.status}, ` + `message: ${error.message}`;
    }

    return throwError(() => error);
  }
}