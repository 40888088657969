import { PostOfficeModels } from './model/post-office-model';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, firstValueFrom, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalVariablesService } from '../global-variables.service';
import { LocationHubConnectionService } from '../hubs/location-hub-connection.service';
import { Guid } from '../../constants/constants';
import { PropertyHubConnectionService } from '../hubs/property-hub-connection.service';
import { LiveCacheDTOs } from './dto/live-cache-dto';


@Injectable({
  providedIn: 'root'
})
export class PostOfficeService {



  private endpoints = {
    zones: '/live-cache/zones',
    tags: '/live-cache/tags',
    tagProperties: '/live-cache/tag-properties',
    tagPropertyTemplates: '/live-cache/tag-property-templates',
    trackItems: '/live-cache/track-items',
    tagLocations: '/live-cache/tag-live-locations',
  };

  private dataStores = {
    zones: [] as LiveCacheDTOs.Zone[],
    tags: [] as LiveCacheDTOs.Tag[],
    tagProperties: [] as LiveCacheDTOs.TagProperty[],
    tagPropertyTemplates: [] as LiveCacheDTOs.TagPropertyTemplate[],
    trackItems: [] as LiveCacheDTOs.TrackItem[],
    liveLocations: [] as LiveCacheDTOs.LiveLocation[]
  };

  public zoneUpdatedSubject = new BehaviorSubject<LiveCacheDTOs.Zone>(null);
  public tagUpdatedSubject = new BehaviorSubject<LiveCacheDTOs.Tag>(null);
  public tagPropertyUpdatedSubject = new BehaviorSubject<LiveCacheDTOs.TagProperty>(null);
  public tagPropertyTemplateUpdatedSubject = new BehaviorSubject<LiveCacheDTOs.TagPropertyTemplate>(null);
  public trackItemUpdatedSubject = new BehaviorSubject<LiveCacheDTOs.TrackItem>(null);
  public liveLocationUpdatedSubject = new BehaviorSubject<LiveCacheDTOs.LiveLocation>(null);

  public tagInfos = new Map<Guid, PostOfficeModels.TagInfoModel>();

  constructor(
    private http: HttpClient,
    private varService: GlobalVariablesService,
    private locationHub: LocationHubConnectionService,
    private propertyHub: PropertyHubConnectionService) {

  }

  init(): void {

    this.locationHub.locationUpdated$.subscribe(async (data) => {
      if (!data)
        return;

      const tagLocation = this.dataStores?.liveLocations?.firstOrDefault(p => p.tagSessionHistoryId == data.tagSessionHistoryId);


      if (tagLocation == null)
        return;

      tagLocation.firstSeenAt = data.firstSeen;
      tagLocation.lastSeenAt = data.lastSeen;
      tagLocation.zoneChangeDuration = data.zoneChangeDuration;
      tagLocation.zoneDuration = data.zoneDuration;
      tagLocation.zoneId = data.currentZoneId;
      tagLocation.previousZoneId = data.previousZoneId;
      tagLocation.locationUpdateStatus = data?.locationUpdateStatus;
      tagLocation.zoneName = this.dataStores?.zones?.firstOrDefault(p => p.id == data.currentZoneId)?.name;


      this.liveLocationUpdatedSubject.next(tagLocation);

      if (this.tagInfos.has(data.tagSessionHistoryId)) {
        this.tagInfos.get(data.tagSessionHistoryId).liveLocation = tagLocation;
      }

    });

    this.propertyHub.propertyUpdated$.subscribe((data) => {
      if (!data)
        return;


      data?.properties?.forEach(property => {
        const tagProperty = this.dataStores?.tagProperties?.firstOrDefault(p => p.id == property.tagPropertyId);
        if (tagProperty) {

          tagProperty.tagSessionHistoryId = data.tagSessionHistoryId;
          tagProperty.value = property.value;
          tagProperty.booleanValue = property.booleanValue;
          tagProperty.numberValue = property.numberValue;
          tagProperty.dateTimeValue = property.dateTimeValue;
          tagProperty.readAt = property.readAt;
          tagProperty.updateRequired = property.updateRequired;
          this.tagPropertyUpdatedSubject.next(tagProperty);
        }
      });


    });

    const result$ = forkJoin({
      zones: this.retrieveAll<LiveCacheDTOs.Zone[]>('zones'),
      tags: this.retrieveAll<LiveCacheDTOs.Tag[]>('tags'),
      tagProperties: this.retrieveAll<LiveCacheDTOs.TagProperty[]>('tagProperties'),
      tagPropertyTemplates: this.retrieveAll<any[]>('tagPropertyTemplates'),
      trackItems: this.retrieveAll<LiveCacheDTOs.TrackItem[]>('trackItems'),
      tagLocations: this.retrieveAll<LiveCacheDTOs.LiveLocation[]>('tagLocations')
    });

    result$.subscribe((data) => {
      this.dataStores.zones = data.zones;
      this.dataStores.tags = data.tags;
      this.dataStores.tagProperties = data.tagProperties;
      this.dataStores.tagPropertyTemplates = data.tagPropertyTemplates;
      this.dataStores.trackItems = data.trackItems;
      this.dataStores.liveLocations = data.tagLocations;

      // Initialize tagInfos
      this.initTagInfos();

    });
  }

  initTagInfos() {
    this.dataStores.tags.forEach(tag => {
      let tagInfo = new PostOfficeModels.TagInfoModel();
      tagInfo.tag = tag;
      tagInfo.tagProperties = this.dataStores.tagProperties.where(p => p.tagId == tag.id).toArray();
      tagInfo.tagProperties?.forEach(tagProperty => {
        const tpt = this.dataStores?.tagPropertyTemplates?.firstOrDefault(p => p.id == tagProperty.tagPropertyTemplateId);

        if (tpt) {
          tagProperty.name = tpt.name;
          tagProperty.querySelector = tpt.querySelector;
        }

      });

      tagInfo.trackItem = this.dataStores?.trackItems?.firstOrDefault(p => p.id == tag.trackItemId);
      if (tagInfo.trackItem?.zoneId)
        tagInfo.trackItem.zoneName = this.dataStores?.zones?.firstOrDefault(p => p.id == tagInfo.trackItem?.zoneId)?.name;

      tagInfo.liveLocation = this.dataStores?.liveLocations?.firstOrDefault(p => p.tagSessionHistoryId == tag.tagSessionHistoryId);

      if (tagInfo.liveLocation)
        tagInfo.liveLocation.zoneName = this.dataStores?.zones?.firstOrDefault(p => p.id == tagInfo.liveLocation.zoneId)?.name;


      this.tagInfos.set(tag.tagSessionHistoryId, tagInfo);
    });

  }

  retrieveAll<T>(endpointKey: string): Observable<T> {
    const url = this.varService.apiV2Address + this.endpoints[endpointKey];
    return this.http.get<T>(url).pipe(
      tap(data => {
        this.dataStores[endpointKey] = data;
      })
    );
  }

  resolve(endpointKey: string, id: string): Observable<any> {
    const array = this.dataStores[endpointKey];
    const item = array.find(x => x.id === id);
    if (item) {
      return new Observable(observer => {
        observer.next(item);
        observer.complete();
      });
    } else {
      const url = `${this.varService.apiV2Address}${this.endpoints[endpointKey]}/${id}`;
      return this.http.get<any>(url).pipe(
        tap(data => {
          array.push(data);
        })
      );
    }
  }


  async getZone(zoneId: Guid, fireEvent: boolean = true): Promise<LiveCacheDTOs.Zone> {
    let zone = this.dataStores?.zones?.firstOrDefault(x => x.id == zoneId);

    if (!zone) {
      // Önbellekte yoksa, HTTP isteği yap
      const url = `${this.varService.apiV2Address}${this.endpoints.zones}/${zoneId}`;
      const response = await firstValueFrom(this.http.get<LiveCacheDTOs.Zone[]>(url));
      const itemsToAdd = Array.isArray(response) ? response : [response];
      itemsToAdd.forEach(item => this.dataStores.zones.push(item));
      zone = itemsToAdd?.firstOrDefault();
    }

    if (fireEvent)
      this.zoneUpdatedSubject.next(zone);

    return zone;
  }

  async getTag(id?: Guid, tagSessionHistoryId?: Guid, fireEvent: boolean = true): Promise<LiveCacheDTOs.Tag> {
    let tag: LiveCacheDTOs.Tag | undefined;

    // Eğer id varsa, id'ye göre arama yap
    if (id) {
        tag = this.dataStores?.tags?.firstOrDefault(x => x.id === id);
    }
    // Eğer tagSessionHistoryId varsa, tagSessionHistoryId'ye göre arama yap
    else if (tagSessionHistoryId) {
        tag = this.dataStores?.tags?.firstOrDefault(x => x.tagSessionHistoryId === tagSessionHistoryId);
    }

    if (!tag) {
        let url = `${this.varService.apiV2Address}${this.endpoints.tags}`;

        // Eğer id varsa, id'ye göre URL oluştur
        if (id) {
            url += `/${id}`;
        }
        // Eğer tagSessionHistoryId varsa, tagSessionHistoryId'ye göre URL oluştur
        else if (tagSessionHistoryId) {
            url += `?tagSessionHistoryId=${tagSessionHistoryId}`;
        }

        const response = await firstValueFrom(this.http.get<LiveCacheDTOs.Tag | LiveCacheDTOs.Tag[]>(url));
        const itemsToAdd = Array.isArray(response) ? response : [response];
        itemsToAdd.forEach(item => this.dataStores.tags.push(item));
        tag = itemsToAdd?.firstOrDefault();
    }

    if (fireEvent && tag) {
        this.tagUpdatedSubject.next(tag);
    }

    return tag;
}

  // async getTag(id?: Guid, tagSessionHistoryId?: Guid, fireEvent: boolean = true): Promise<LiveCacheDTOs.Tag> {
  //   // Önbellekte arama yap
  //   let tag = this.dataStores?.tags?.firstOrDefault(x => x.id === id);

  //   if (!tag) {
  //     // Önbellekte yoksa, HTTP isteği yap
  //     const url = `${this.varService.apiV2Address}${this.endpoints.tags}/${id}`;
  //     const response = await firstValueFrom(this.http.get<LiveCacheDTOs.Tag | LiveCacheDTOs.Tag[]>(url));
  //     const itemsToAdd = Array.isArray(response) ? response : [response];
  //     itemsToAdd.forEach(item => this.dataStores.tags.push(item));
  //     tag = itemsToAdd?.firstOrDefault();
  //   }

  //   if (fireEvent)
  //     this.tagUpdatedSubject.next(tag);

  //   return tag;
  // }

  async getTagProperty(tagPropertyId: Guid, fireEvent: boolean = true): Promise<LiveCacheDTOs.TagProperty> {
    let tagProperty = this.dataStores?.tagProperties?.firstOrDefault(x => x.id === tagPropertyId);

    if (!tagProperty) {
      // Önbellekte yoksa, HTTP isteği yap
      const url = `${this.varService.apiV2Address}${this.endpoints.tagProperties}/${tagPropertyId}`;
      const response = await firstValueFrom(this.http.get<LiveCacheDTOs.TagProperty | LiveCacheDTOs.TagProperty[]>(url));
      const itemsToAdd = Array.isArray(response) ? response : [response];
      itemsToAdd.forEach(item => this.dataStores.tagProperties.push(item));
      tagProperty = itemsToAdd?.firstOrDefault();
    }

    if (fireEvent)
      this.tagPropertyUpdatedSubject.next(tagProperty);

    return tagProperty;
  }

  async getTagPropertyTemplate(tagPropertyTemplateId: Guid, fireEvent: boolean = true): Promise<LiveCacheDTOs.TagPropertyTemplate> {

    let tagPropertyTemplate = this.dataStores?.tagPropertyTemplates?.firstOrDefault(x => x.id === tagPropertyTemplateId);

    if (!tagPropertyTemplate) {
      // If not found in cache, make an HTTP request
      const url = `${this.varService.apiV2Address}${this.endpoints.tagPropertyTemplates}/${tagPropertyTemplateId}`;
      const response = await firstValueFrom(this.http.get<LiveCacheDTOs.TagPropertyTemplate | LiveCacheDTOs.TagPropertyTemplate[]>(url));
      const itemsToAdd = Array.isArray(response) ? response : [response];
      itemsToAdd.forEach(item => this.dataStores.tagPropertyTemplates.push(item));
      tagPropertyTemplate = itemsToAdd?.firstOrDefault();
    }

    if (fireEvent)
      this.tagPropertyTemplateUpdatedSubject.next(tagPropertyTemplate);

    return tagPropertyTemplate;
  }


  async getTrackItem(id: Guid, fireEvent: boolean = true): Promise<LiveCacheDTOs.TrackItem> {
    let trackItem = this.dataStores?.trackItems?.firstOrDefault(x => x.id === id);

    if (!trackItem) {

      const url = `${this.varService.apiV2Address}${this.endpoints.trackItems}/${id}`;
      const response = await firstValueFrom(this.http.get<any>(url));
      const itemsToAdd = Array.isArray(response) ? response : [response];
      itemsToAdd.forEach(item => this.dataStores.trackItems.push(item));
      trackItem = itemsToAdd?.firstOrDefault();
    }

    if (fireEvent)
      this.trackItemUpdatedSubject.next(trackItem);

    return trackItem;
  }

  async getLiveLocation(tagSessionHistoryId: Guid, fireEvent: boolean = true): Promise<LiveCacheDTOs.LiveLocation> {
    let location = this.dataStores?.liveLocations?.firstOrDefault(p => p.tagSessionHistoryId == tagSessionHistoryId);

    if (!location) {
      // If not found in cache, make an HTTP request
      const url = `${this.varService.apiV2Address}${this.endpoints.tagLocations}/${tagSessionHistoryId}`;
      const response = await firstValueFrom(this.http.get<LiveCacheDTOs.LiveLocation | LiveCacheDTOs.LiveLocation[]>(url));
      const itemsToAdd = Array.isArray(response) ? response : [response];
      itemsToAdd.forEach(item => this.dataStores.liveLocations.push(item));
      location = itemsToAdd?.firstOrDefault();
    }

    if (fireEvent) {
      this.liveLocationUpdatedSubject.next(location);
    }

    return location;
  }

  getTagInfo(tagSessionHistoryId: Guid): PostOfficeModels.TagInfoModel {
    return this.tagInfos.get(tagSessionHistoryId);
  }

}
