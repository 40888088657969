import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '@app/shared/shared.module';
import { UserProfileComponent } from './user-profile.component';
import { RouterModule } from '@angular/router';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { FuseAlertModule } from '@fuse/components/alert';

@NgModule({
    declarations: [UserProfileComponent, ChangePasswordComponent],
    imports: [

        RouterModule,

        CommonModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,

        FuseAlertModule,

        SharedModule
    ],
    exports: [UserProfileComponent]
})
export class UserProfileModule { }
