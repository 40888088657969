import { Injectable } from '@angular/core';

@Injectable()
export class EnumService {

    constructor() { }

    public getEnumType(enumClass): any {
        const arr = [];
        const enumType = enumClass;
        for (const n in enumType) {
            if (typeof enumType[n] === 'number')
                {arr.push({
                    'key': n,
                    'value': enumType[n]
                });}
        }
        return arr;
    }

    public getDescription(e: any, id: number): string {
        return e[e[id].toString() + 'Description'];
    }
}
