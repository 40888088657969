import { Injectable } from '@angular/core';
import { BaseHubConnection } from './base-hub-connection.service';
import { TagLocationModel } from './models/tag-location.model';
import { BehaviorSubject } from 'rxjs';
import { GlobalVariablesService } from '../global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class LocationHubConnectionService extends BaseHubConnection {
  private locationUpdatedSubject: BehaviorSubject<TagLocationModel> = new BehaviorSubject<TagLocationModel>(null);
  public locationUpdated$ = this.locationUpdatedSubject.asObservable();


  constructor(private globals: GlobalVariablesService) {

    super();



  }

  public init() {
    this.initializeHubConnection(`${this.globals.tracker.address}/location-hub`);

    this.connectionStatus.subscribe((status) => {
      console.log('LocationHubConnectionService status:', status);
    }
    );

    this.hubConnection.on("LocationUpdated", (data: any) => {
      const location = new TagLocationModel(data);
      this.locationUpdatedSubject.next(location);
    });
  }

}
