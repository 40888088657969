import { Pipe, PipeTransform, Inject } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);

@Pipe({
  name: 'datetimeFormat',
  pure: false
})
export class DatetimeFormatPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    dayjs.locale(this.locale);
  }

  transform(value: string | number | Date, format: string = 'L LTS'): string {
    return dayjs.tz(new Date(value)).format(format);
  }      

}