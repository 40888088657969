import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RtlsCountPieGraphicComponent } from './rtls-count-pie-graphic.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        RtlsCountPieGraphicComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),

        NgApexchartsModule,
        MaterialModule
    ],
    exports: [RtlsCountPieGraphicComponent]
})
export class RtlsCountPieGraphicModule { }
