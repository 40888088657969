import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseValidators } from '@fuse/validators';
import { FuseAlertType } from '@fuse/components/alert';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DataService } from '@app/core/services/data.service';
import { BadisOauthLoginService } from '@app/core/services/auth/badis-oauth-login.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PasswordValidatorService } from '@app/shared/services/password-validator.service';  // Password güçlendirme servisi

@Component({
    selector: 'auth-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ChangePasswordComponent implements OnInit {
    @ViewChild('resetPasswordNgForm') resetPasswordNgForm: NgForm;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: ''
    };

    resetPasswordForm: FormGroup;
    showAlert: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ChangePasswordComponent>,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _authService: BadisOauthLoginService,
        private _formBuilder: FormBuilder,
        private dataService: DataService,
        private passwordValidator: PasswordValidatorService  // Servisi enjekte edin
    ) { }

    ngOnInit(): void {
        this.resetPasswordForm = this._formBuilder.group({
            id: [this._authService.userAccessToken.userId, Validators.required],
            oldPassword: [null, Validators.required],
            newPassword: [null, [Validators.required, this.passwordValidator.passwordStrengthValidator()]],  // Güçlendirme doğrulayıcısı eklendi
            passwordConfirm: [null, Validators.required]
        },
        { validators: FuseValidators.mustMatch('newPassword', 'passwordConfirm') }  // Şifre eşleştirme doğrulayıcısı
        );
    }

    public onClose(): void {
        this.dialogRef.close();
    }

    sendChangePassword(): void {
        if (this.resetPasswordForm.invalid) {
            return;
        }

        if (this.resetPasswordForm.controls.newPassword.value === this.resetPasswordForm.controls.oldPassword.value) {
            this.alert.type = 'error';
            this.alert.message = 'New password and old password cannot be same';  // Çevirilecek mesaj
            this.showAlert = true;

            return;
        }

        this.resetPasswordForm.disable();
        this.showAlert = false;

        this._authService.resetPassword(this.resetPasswordForm.value)
            .pipe(
                finalize(() => {
                    this.resetPasswordForm.enable();
                    this.resetPasswordNgForm.resetForm();
                    this.showAlert = true;
                })
            )
            .subscribe((response: any) => {
                if (response.success) {
                    this.alert = { type: 'success', message: 'Your password has been reset.' };  // Çevirilecek mesaj
                    this.onClose();
                } else {
                    const msg = this.dataService.getTranslated(response.messageCode);
                    this.alert = { type: 'error', message: msg };
                }
            },
                (error: HttpErrorResponse) => {
                    this.alert = {
                        type: 'error',
                        message: 'Something went wrong, please try again.'  // Çevirilecek mesaj
                    };
                    this.showAlert = true;
                });
    }
}
