
export class SecurityAlert {
  content: string;
  date: Date;
  photoUrl: string;
  
  locateUrl: string;
  locateUrlParams:any;

  detailsUrl: string;
  detailsUrlParams: any;
  title: string;
  alert: boolean;
}
