import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';

@Injectable()
export class FuseSplashScreenService {
    // Zamanlayıcı için bir referans tutacak değişken
    private _timeoutRef: any;

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: any,
        private _router: Router
    ) {
        // Hide it on the first NavigationEnd event
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                this.hide();
            });

        // Yükleme süresi kontrolü için zamanlayıcıyı başlat
        this.startLoadingTimeout();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the splash screen
     */
    show(): void {
        this._document.body.classList.remove('fuse-splash-screen-hidden');
        // Her show çağrısında zamanlayıcıyı sıfırla
        clearTimeout(this._timeoutRef);
        this.startLoadingTimeout();
    }

    /**
     * Hide the splash screen
     */
    hide(): void {
        this._document.body.classList.add('fuse-splash-screen-hidden');
        // Hide çağrıldığında zamanlayıcıyı iptal et
        clearTimeout(this._timeoutRef);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Belirlenen süre içinde yükleme tamamlanmazsa sayfayı yenile
     */
    private startLoadingTimeout(): void {
        // 45 saniye sonra çalışacak zamanlayıcı
        this._timeoutRef = setTimeout(() => {
            window.location.reload();
        }, 45000); // 45 saniye
    }
}
