import { NgModule } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

@NgModule()
export class IconsModule {
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry,
        public library: FaIconLibrary
    ) {
        // todo: mesut: dig later
        this._matIconRegistry.addSvgIconResolver((name, namespace) => this.resolveIcon(namespace, name));

        // this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
        // this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
        // this._matIconRegistry.addSvgIconSetInNamespace('mat_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'));
        // this._matIconRegistry.addSvgIconSetInNamespace('iconsmind', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg'));
        // this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
        // this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
        // this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));
        // this._matIconRegistry.addSvgIconSetInNamespace('fontawesome_light', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fontawesome-light.svg'));

    }

    private resolveIcon(namespace: any, name: any): SafeResourceUrl {

        if (namespace === 'fontawesome_light')// remap theme hard coded icons to fontawesome
        {
            namespace = 'fal';
            switch (name) {
                case '':
                    break;
                //...
                //...
                //...

                default:
                    console.error(`IconModule: 'fontawesome_light:${name}' requested but there is no existing mapping yet!`);
                    name = 'question-square';
                    break;
            }

        } else if (namespace === 'heroicons_solid') {
            
            namespace = 'fal';
            switch (name) {
                case 'chevron-right':
                    name = 'chevron-right';
                    break;

                case 'dots-vertical':
                    namespace = 'far';
                    name = 'ellipsis-v';
                    break;

                case 'x-circle':
                    namespace = 'fal';
                    name = 'circle-xmark';
                    break;

                //...
                //...
                //...

                default:
                    console.error(`IconModule: 'heroicons_solid:${name}' requested but there is no existing mapping yet!`);
                    name = 'question-square';
                    break;
            }
        } else if (namespace === 'heroicons_outline') {
            namespace = 'fal';
            switch (name) {
  
                case 'x':
                    namespace = 'fal';
                    name = 'xmark';
                    break;

                    case 'search':
                        namespace = 'fal';
                        name = 'search';
                        break;

                default:
                    console.error(`IconModule: 'heroicons_outline:${name}' requested but there is no existing mapping yet!`);
                    name = 'question-square';
                    break;
            }
        }


        let iconDef = this.library.getIconDefinition(namespace as IconPrefix, name as IconName);
        if (!iconDef) { iconDef = this.library.getIconDefinition('fal', 'times-hexagon'); }

        const svgIcon =
            `<svg role="img" aria-hidden="true"
            focusable="false" data-prefix="${iconDef.prefix}"
            data-icon="${iconDef.iconName}" class="svg-inline--fa fa-${iconDef.iconName}"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 ${iconDef.icon[0]} ${iconDef.icon[1]}">
            <path fill="currentColor" d="${iconDef.icon[4]}"></path>
        </svg>`;

        const blob = new Blob([svgIcon], { type: 'image/svg+xml'  });
        const url = URL.createObjectURL(blob);
        return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
