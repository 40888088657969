import { ModuleWithProviders, NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { RtlsToolBarComponent } from './components/rtls-tool-bar/rtls-tool-bar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        RtlsToolBarComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule,
        MaterialModule,

        NgSelectModule,

        FontAwesomeModule,
        MaterialModule
    ],
    exports: [RtlsToolBarComponent]
})
export class RtlsToolBarModule {
    static forRoot(): ModuleWithProviders<RtlsToolBarModule> {
        return {
            ngModule: RtlsToolBarModule,
            providers: []
        };
    }
}
