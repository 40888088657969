import { CommonBaseService } from '@app/shared/services/common-base.service';
import { ReplaySubject, Subject } from 'rxjs';

export class ServiceBase  extends CommonBaseService{

    public readyBehavior = new ReplaySubject<boolean>(1);
    public isReady = this.readyBehavior.asObservable();
    public serviceSubject = new Subject<void>();

  constructor() { 
    super();
  }
}
