/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.production) {
    enableProdMode();

    if (window) {
        window.console.log = () => { };
    }

}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(module => enableDebugTools(module.injector.get(ApplicationRef).components[0]))
  .catch(err => console.error(err));

/*
platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
*/

//console debugger --> ng.profiler.timeChangeDetection()
