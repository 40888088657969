import { Pipe, PipeTransform, Inject } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// Day.js ve relativeTime eklentisini genişletme
dayjs.extend(relativeTime);

@Pipe({
  name: 'relativeTime',
  pure:false
})
export class RelativeTimePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    dayjs.locale(this.locale);
  }

  transform(value: string | number | Date, suffix: boolean = false): string {
 
    return dayjs.tz(new Date(value)).fromNow(!suffix);
  }
}