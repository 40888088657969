import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariablesService } from './global-variables.service';
import { CustomReport } from '@app/shared/classes/custom-reports.model';
import { Guid } from '../constants/constants';

@Injectable()
export class CustomReportService {

    public customReports: CustomReport[] = []; // Custom reports array

    constructor(
        private http: HttpClient,
        private varService: GlobalVariablesService) { }


    // Fetch user custom reports
    public getUserCustomReports(): Observable<CustomReport[]> {
        const url = `${this.varService.apiV2Address}/custom-reports`;
        return this.http.get<CustomReport[]>(url); // Change 'any' to 'CustomReport'
    }

    // Add a new custom report
    public addCustomReport(report: CustomReport): Observable<CustomReport> {
        const url = `${this.varService.apiV2Address}/custom-reports`;
        return this.http.post<CustomReport>(url, report);
    }

    // Update an existing custom report
    public updateCustomReport(report: CustomReport): Observable<CustomReport> {
        const url = `${this.varService.apiV2Address}/custom-reports/${report.id}`;
        return this.http.put<CustomReport>(url, report);
    }

    // Delete a custom report
    public deleteCustomReport(reportId: Guid): Observable<CustomReport> {
        const url = `${this.varService.apiV2Address}/custom-reports/${reportId}`;
        return this.http.delete<CustomReport>(url);
    }
}

