<div class="flex flex-col items-center justify-center min-w-0 bg-transparent">
    <div class="w-full max-w-md p-8 ">
        <div class="text-2xl font-bold mb-6">
            {{ 'SECURITY.CHANGE_PASSWORD' | translate }}
        </div>

        <!-- Alert for success or error messages -->
        <fuse-alert class="mb-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type">
            {{ alert.message }}
        </fuse-alert>

        <!-- Password Change Form -->
        <form [formGroup]="resetPasswordForm" #resetPasswordNgForm="ngForm">

            <!-- Old Password Field -->
            <mat-form-field class="w-full mb-4">
                <mat-label>{{ "SECURITY.PASSWORD" | translate }}</mat-label>
                <input matInput type="password" formControlName="oldPassword" #passwordField>
                <button mat-icon-button type="button" (click)="passwordField.type = passwordField.type === 'password' ? 'text' : 'password'" matSuffix>
                    <fa-icon [icon]="passwordField.type === 'password' ? ['fas', 'eye-slash'] : ['fas', 'eye']"></fa-icon>
                </button>
            </mat-form-field>

            <!-- New Password Field -->
            <mat-form-field class="w-full mb-4">
                <mat-label>{{ "SECURITY.NEW_PASSWORD" | translate }}</mat-label>
                <input matInput type="password" formControlName="newPassword" #newPasswordField>
                <button mat-icon-button type="button" (click)="newPasswordField.type = newPasswordField.type === 'password' ? 'text' : 'password'" matSuffix>
                    <fa-icon [icon]="newPasswordField.type === 'password' ? ['fas', 'eye-slash'] : ['fas', 'eye']"></fa-icon>
                </button>
                <mat-error *ngIf="resetPasswordForm.get('newPassword')?.errors?.required">
                    {{ 'SECURITY.FIELD_REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="resetPasswordForm.get('newPassword')?.errors?.passwordStrength">
                    {{ 'SECURITY.PASSWORD_STRENGTH' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Confirm New Password Field -->
            <mat-form-field class="w-full mb-4">
                <mat-label>{{ "SECURITY.NEW_PASSWORD_VERIFY" | translate }}</mat-label>
                <input matInput type="password" formControlName="passwordConfirm" #confirmPasswordField>
                <button mat-icon-button type="button" (click)="confirmPasswordField.type = confirmPasswordField.type === 'password' ? 'text' : 'password'" matSuffix>
                    <fa-icon [icon]="confirmPasswordField.type === 'password' ? ['fas', 'eye-slash'] : ['fas', 'eye']"></fa-icon>
                </button>
                <mat-error *ngIf="resetPasswordForm.get('passwordConfirm')?.errors?.required">
                    {{ 'SECURITY.FIELD_REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="resetPasswordForm.hasError('mustMatch') && !resetPasswordForm.get('passwordConfirm')?.errors?.required">
                    {{ 'SECURITY.PASSWORDS_MUST_MATCH' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Change Password Button -->
            <button class="w-full mt-4 btn btn-primary" mat-raised-button [disabled]="!resetPasswordForm.valid" (click)="sendChangePassword()">
                {{ 'SECURITY.CHANGE_PASSWORD' | translate }}
            </button>

            <!-- Cancel Button -->
            <div class="mt-4 text-center">
                <button mat-button class="text-primary-500 hover:underline" (click)="onClose()">
                    {{ 'SYSTEM.CANCEL' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>
