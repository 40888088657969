import { Guid } from "@app/core/constants/constants";

export class TagAlert {
    tagId: Guid;
    tagSessionHistoryId: Guid;
    tagCode: string;
    tagPropertyTemplateId: string;
    propertyName: string;
    readAt: Date;

    value: string;
    numberValue: number;
    booleanValue: boolean;
    dateTimeValue: Date;
    alert: AlertValidationResult



    constructor(data?: any) {
        if (data) {

            Object.assign(this, data);

            this.readAt = new Date(data.readAt);
            this.dateTimeValue = new Date(data.dateTimeValue);

        }
    }
}

export enum AlertValidationResult {
    NoAlertSpecified,
    Alert,
    NoAlert
}