import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BadisOauthLoginService } from '@app/core/services/auth/badis-oauth-login.service';

import { DataService } from '@app/core/services/data.service';
import { Subject } from 'rxjs';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit, OnDestroy {

    @Input() showAvatar: boolean = true;

    public authUser: any = {};

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _dialog: MatDialog,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _authService: BadisOauthLoginService,
        private dataService: DataService,
    ) {
    }

    ngOnInit(): void {

        this._authService.onAuthStatusChanged.subscribe((isAuthenticated: boolean) => {
            if (isAuthenticated) {
                this.authUser = this._authService.userAccessToken;
            }

        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    public photoLink(): any {
        return this.dataService.getAvatarPhoto(this.authUser.avatar);
    }

    public errorHandler(event: any): any {
        event.target.src = './assets/images/placeholders/placeholder-small.png';
    }

    updateUserStatus(status: string): void {
        // // Return if user is not available
        // if (!this.user) {
        //     return;
        // }

        // // Update the user
        // this._userService.update({
        //     ...this.user,
        //     status
        // }).subscribe();
    }

    signOut(): void {
        this._router.navigate(['/auth/sign-out']);
    }

    public changePassword(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';
        dialogConfig.height = 'auto';
        dialogConfig.disableClose = true;
        dialogConfig.hasBackdrop = true;
        this._dialog
            .open(ChangePasswordComponent, dialogConfig)
            .afterClosed()
            .subscribe((result: any) => {

            });
    }

}
