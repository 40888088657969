import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';
import { SharedModule } from 'app/shared/shared.module';
import { ActiveTagsComponent } from './active-tags.component';
import { FilterActiveTagsComponent } from './filter/filter-active-tags.component';

@NgModule({
    declarations: [ActiveTagsComponent, FilterActiveTagsComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseDrawerModule,
        FuseScrollbarModule,
        SharedModule,
    ],
    exports: [ActiveTagsComponent, FilterActiveTagsComponent]
})
export class ActiveTagsModule
{
}
