import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackBarDialogComponent } from '@app/shared/components/snackbar-dialog/snackbar-dialog.component';

@Injectable()
export class SnackBarService {
  snackBarConfig: any;
  snackBarRef: any;
  dismissed: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'left';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  snackBarAutoHide = 7000;
  panelClass: any = 'err-snackbar';

  constructor(private snackBar: MatSnackBar) {}

  public openSuccessSnackBar(message: string): MatSnackBarRef<any> {
    this.panelClass = 'success-snackbar';
    this.openSnackBar(message);
    return this.snackBarRef;
  }

  public openErrorSnackBar(message: string): MatSnackBarRef<any> {
    this.panelClass = 'err-snackbar';
    this.openSnackBar(message);
    return this.snackBarRef;
  }

  public openInfoSnackBar(message: string): MatSnackBarRef<any> {
    this.panelClass = 'info-snackbar';
    this.openSnackBar(message);
    return this.snackBarRef;
  }

  private openSnackBar(message: string): MatSnackBarRef<any> {
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.horizontalPosition = this.horizontalPosition;
    this.snackBarConfig.verticalPosition = this.verticalPosition;
    this.snackBarConfig.duration = this.snackBarAutoHide;
    this.snackBarConfig.panelClass = this.panelClass;

    this.snackBarRef = this.snackBar.openFromComponent(SnackBarDialogComponent,
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: this.snackBarAutoHide,
        panelClass: this.panelClass,
        data: { message, type: this.panelClass, ref: this.snackBar },
      }
    );
    return this.snackBarRef;
  }

}


