import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsVisibleForAuthUserDirective } from './is-visible-for-auth-user.directive';
import { HasAuthUserViewPermissionDirective } from './has-auth-user-view-permission.directive';

@NgModule({
    declarations: [IsVisibleForAuthUserDirective, HasAuthUserViewPermissionDirective],
    imports: [CommonModule],
    exports: [IsVisibleForAuthUserDirective, HasAuthUserViewPermissionDirective]
})
export class ElementVisibleUserModule { }
