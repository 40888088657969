/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/prefer-namespace-keyword */

import { Comparison, FontStyle, StyleAction, TableTarget, TrackItemKind, ValueTypes } from '@app/core/constants/global-enum';
import { LocationUpdateStatus } from '@app/core/services/hubs/models/tag-location.model';

// http://json2ts.com/
export module LiveDTO {

    export enum ChangeRequest {
        User = 0,
        Initial = 1
    }

    export enum TagShape {
        Circle = 0,
        Square = 1,
        Rectangle = 2
    }

    export enum TileExtensions {
        Png = 0,
        Jpeg = 1,
        WithoutExtension = 2
    }


    export class StyleRule {
        applyOrder: number;
        comparison: Comparison;
        value: string;
        valueEnd: string;
        displayValue: string;
        colorCode: string;
        backgroundColorCode: string;
        icon: string;
        iconColorCode: string;
        animationName: string;
        iconUrl: string;
        tableTarget: TableTarget;
        fontStyle: FontStyle;
        styleAction: StyleAction;
    }


    export class TagPropertyTemplate {

        id: string;
        name: string;
        description: string;
        prefix: string;
        suffix: string;
        icon: string;
        colorCode: string;
        defaultValue: string;
        valueType: ValueTypes;
        tagTypeId: string;
        querySelector: string;
        invalidValueA: string;
        invalidValueB: string;
        invalidComparator: Comparison;
        styleRules: StyleRule[];
    }

    export class TagLocation {
        tagSessionHistoryId: string;

        previousZoneId?: string;
        currentZoneId: string;
        currentFloorId: string;
        longitude: string;
        latitude: string;
        zoneDuration: number;
        zoneChangeDuration: number;
        distance: number;
        firstSeen: Date;
        lastSeen: Date;

        tagName: string;
        tagShape: TagShape;
        trackItemPhotoUrl: string;
        trackItemSessionId: string;
        trackItemId: string;
        trackItemDescription: string;
        trackItemKind: TrackItemKind;
        trackItemTypeId: string;
        previousZoneName: string;
        currentZoneName: string;

        locationUpdateStatus: LocationUpdateStatus;
    }

    export interface ZoneTagsCount {
        zoneId: string;
        count: number;
    }

    export interface Map {
        title: string;
        description: string;
        imagePath?: any;
        width: number;
        height: number;
        longitude: number;
        latitude: number;
        zoomLevel: number;
        tmsPath?: any;
        attribution?: any;
        geoJSON: string;
        floors: any[];
        id: string;
        createdById: string;
        createdBy?: any;
        createdAt: Date;
        updatedById: string;
        updatedBy?: any;
        updatedAt: Date;
        recordState: number;
    }

    export interface Reader {
        connectionValue: string;
        name: string;
        description?: any;
        photoUrl?: any;
        hardware: number;
        type: number;
        detail?: any;
        outputs: any[];
        antennas: any[];
        id: string;
        createdById: string;
        createdBy?: any;
        createdAt: Date;
        updatedById: string;
        updatedBy?: any;
        updatedAt: Date;
        recordState: number;
    }

    export interface Antenna {
        name: string;
        description?: any;
        port: number;
        power: number;
        isWriteable: boolean;
        readerId: string;
        gateDirectionId: string;
        longitude: number;
        latitude: number;
        reader: Reader;
        accessHistories: any[];
        id: string;
        createdById: string;
        createdBy?: any;
        createdAt: Date;
        updatedById: string;
        updatedBy?: any;
        updatedAt: Date;
        recordState: number;
    }

    export interface Direction {
        name: string;
        direction: number;
        main: boolean;
        gateId: string;
        outputs: any[];
        antennas: Antenna[];
        accessRuleGateDirections: any[];
        id: string;
        createdById: string;
        createdBy?: any;
        createdAt: Date;
        updatedById: string;
        updatedBy?: any;
        updatedAt: Date;
        recordState: number;
    }

    export interface Gate {
        name: string;
        photoUrl?: any;
        directions: Direction[];
        zoneId: string;
        accessRuleGates: any[];
        id: string;
        createdById: string;
        createdBy?: any;
        createdAt: Date;
        updatedById: string;
        updatedBy?: any;
        updatedAt: Date;
        recordState: number;
    }

    export interface Zone {
        name: string;
        shortName?: any;
        code: string;
        colorCode?: any;
        iconCode?: any;
        description?: any;
        geoJSON: string;
        zoomLevel: number;
        capacity: number;
        floorId: string;
        gates: Gate[];
        items?: any;
        stocktakings?: any;
        accessRuleZones?: any;
        id: string;
        createdById: string;
        createdBy?: any;
        createdAt: Date;
        updatedById: string;
        updatedBy?: any;
        updatedAt: Date;
        recordState: number;
    }

    export interface Floor {
        name: string;
        description: string;
        photoUrl?: any;
        no: number;
        tileAvailable: boolean;
        tileBounds1Latitude: number;
        tileBounds1Longitude: number;
        tileBounds2Latitude: number;
        tileBounds2Longitude: number;
        geoJSON: string;
        tileExtension: TileExtensions;
        zoomLevel: number;
        buildingId: string;
        mapId: string;
        map: Map;
        zones: Zone[];
        accessRuleFloors?: any;
        id: string;
        createdById: string;
        createdBy?: any;
        createdAt: Date;
        updatedById: string;
        updatedBy?: any;
        updatedAt: Date;
        recordState: number;
    }

    export interface Building {
        name: string;
        description: string;
        longitude: number;
        latitude: number;
        address: string;
        geoJSON: string;
        zoomLevel: number;
        photoUrl: string;
        floors: Floor[];
        accessRuleBuildings?: any;
        id: string;
        createdById: string;
        createdBy?: any;
        createdAt: Date;
        updatedById: string;
        updatedBy?: any;
        updatedAt: Date;
        recordState: number;
    }

    export interface TagProperty {
        tagSessionHistoryId: string;
        id: string;
        tagPropertyTemplateId: string;
        value: string;
        booleanValue: boolean;
        numberValue: number;
        dateTimeValue: Date;
        readAt: Date;
    }

}
