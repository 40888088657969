import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval, merge, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateCheckService {
  // private checkInterval = 1000 * 60 * 15; // 15 dakika
  private checkInterval = 1000 * 60; // 1 dakika
  private updateAvailableSubject = new Subject<boolean>();
  private checkingForUpdateSubject = new Subject<boolean>();

  updateAvailable$ = this.updateAvailableSubject.asObservable();
  checkingForUpdate$ = this.checkingForUpdateSubject.asObservable();

  constructor(private swUpdate: SwUpdate) {
    if (swUpdate.isEnabled) {
      const appStartCheck$ = interval(this.checkInterval);
      const updatesAvailable$ = this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => evt.currentVersion)
      );

      merge(appStartCheck$, updatesAvailable$).subscribe(() => this.checkForUpdates());
    }
  }

  public checkForUpdates() {
    if (!this.swUpdate.isEnabled) {
     // this.updateAvailableSubject.next(true);
      return;
    }

    this.checkingForUpdateSubject.next(true);
    this.swUpdate.checkForUpdate().then(hasUpdate => {
      this.checkingForUpdateSubject.next(false);
      if (hasUpdate) {
        console.log('Yeni bir güncelleme mevcut!');
        this.updateAvailableSubject.next(true);
      }


    }).catch(err => {
      console.error('Güncelleme kontrolü sırasında hata oluştu:', err);
      this.checkingForUpdateSubject.next(false);
    });
  }

  public applyUpdate() {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }
}
