import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, ViewChild } from '@angular/core';
import { default as Vivus } from 'vivus';
@Component({
  selector: 'app-navizard-logo',
  templateUrl: './navizard-logo.component.html',
  styleUrls: ['./navizard-logo.component.scss']
})
export class NavizardLogoComponent implements OnChanges, OnInit, AfterViewInit {

  @ViewChild('navizardLogo', { read: ElementRef, static: true })
  navizardLogoEl: ElementRef;
  @Input() usePreset: boolean = false;
  @Input() isLight: boolean = false;

  @Input() logoFillStart: string = 'rgba(52, 46, 46, 0.05)';
  @Input() logoFill: string = 'rgb(31, 25, 25)';
  @Input() logoStrokeStart: string = 'rgba(0, 0, 0, 0.488)';
  @Input() logoStrokeWidth: number = 4;
  @Input() logoAnimDuration: string = '2s';
  @Input() logoAnimDelay: string = '6s';

  @Input() lightningFillStart: string = 'rgba(52, 46, 46, 0.05)';
  @Input() lightningFill: string = 'rgb(254, 208, 73)';
  @Input() lightningStrokeStart: string = 'rgba(0, 0, 0, 0.488)';
  @Input() lightningAnimDuration: string = '2s';
  @Input() lightningAnimDelay: string = '6s';

  @Input() animationDuration: number = 800;
  @Input() animationType: 'delayed' | 'async' | 'oneByOne' | 'script' | 'scenario' | 'scenario-sync' | 'sync' = 'delayed';

  @Input() logoType: 'v1' | 'v2';


  constructor(private el: ElementRef) {
    if (!this.logoType) {
      //randomize logo type
      const random = Math.floor(Math.random() * 2);
      this.logoType = random == 0 ? 'v1' : 'v2';
    }
  }

  ngOnChanges() {
    const element = this.el.nativeElement;

    if (this.usePreset) {
      if (this.isLight) {
        element.style.setProperty('--logo-fill-start', 'rgba(240, 240, 240, 0.8)');
        element.style.setProperty('--logo-fill', 'rgba(255, 255, 255, 1)');
        element.style.setProperty('--logo-stroke-start', 'rgba(200, 200, 200, 0.12)');
        element.style.setProperty('--lightning-fill-start', 'rgba(240, 240, 240, 0.8)');
        element.style.setProperty('--lightning-fill', 'rgba(255, 255, 255, 0.9)');
        element.style.setProperty('--lightning-stroke-start', 'rgba(210, 210, 210, 0.12)');
      } else {
        element.style.setProperty('--logo-fill-start', 'rgba(52, 46, 46, 0.05)');
        element.style.setProperty('--logo-fill', 'rgb(31, 25, 25)');
        element.style.setProperty('--logo-stroke-start', 'rgba(0, 0, 0, 0.488)');
        element.style.setProperty('--lightning-fill-start', 'rgba(52, 46, 46, 0.05)');
        element.style.setProperty('--lightning-fill', 'rgb(254, 208, 73)');
        element.style.setProperty('--lightning-stroke-start', 'rgba(0, 0, 0, 0.488)');
      }
      element.style.setProperty('--logo-stroke-width', '4px');
      element.style.setProperty('--logo-anim-duration', '2s');
      element.style.setProperty('--logo-anim-delay', '6s');
      element.style.setProperty('--lightning-anim-duration', '2s');
      element.style.setProperty('--lightning-anim-delay', '6s');
    } else {
      element.style.setProperty('--logo-fill-start', this.logoFillStart);
      element.style.setProperty('--logo-fill', this.logoFill);
      element.style.setProperty('--logo-stroke-start', this.logoStrokeStart);
      element.style.setProperty('--logo-stroke-width', this.logoStrokeWidth);
      element.style.setProperty('--logo-anim-duration', this.logoAnimDuration);
      element.style.setProperty('--logo-anim-delay', this.logoAnimDelay);
      element.style.setProperty('--lightning-fill-start', this.lightningFillStart);
      element.style.setProperty('--lightning-fill', this.lightningFill);
      element.style.setProperty('--lightning-stroke-start', this.lightningStrokeStart);
      element.style.setProperty('--lightning-anim-duration', this.lightningAnimDuration);
      element.style.setProperty('--lightning-anim-delay', this.lightningAnimDelay);
    }
  }


  ngOnInit(): void {
  }

  ngAfterViewInit() {
    new Vivus(this.navizardLogoEl.nativeElement, {
      type: this.animationType,
      duration: this.animationDuration,
      forceRender: false,
      pathTimingFunction: Vivus.EASE_OUT,
      animTimingFunction: Vivus.EASE_OUT,
      reverseStack: false,
      selfDestroy: true
    });
  }

}
