import { Injectable } from '@angular/core';
import { BaseHubConnection } from './base-hub-connection.service';
import { TagPropertyWrapper } from './models/tag-property-wrapper.model';
import { BehaviorSubject } from 'rxjs';
import { GlobalVariablesService } from '../global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyHubConnectionService extends BaseHubConnection {

  private propertyUpdatedSubject: BehaviorSubject<TagPropertyWrapper> = new BehaviorSubject<TagPropertyWrapper>(null);
  public propertyUpdated$ = this.propertyUpdatedSubject.asObservable();


  constructor(private globals: GlobalVariablesService) {
    super();
   
   


  }
  public init() {
    this.initializeHubConnection(`${this.globals.tracker.address}/property-hub`);
    this.connectionStatus.subscribe((status) => {
      console.log('PropertyHubConnectionService status:', status);
    }
    );

    this.hubConnection.on("PropertyUpdated", (data: any) => {
      const property = new TagPropertyWrapper(data);
      this.propertyUpdatedSubject.next(property);
    });
  }

}
