import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    CanLoad,
    Route
} from '@angular/router';

import { IGuardPermission } from '../model/guardPermission.interface';
import { BadisOauthLoginService } from '../badis-oauth-login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

    private permissionObjectKey = 'roles';
    private dynamicObjectKey = 'dynamic';

    constructor(private router: Router, private authService: BadisOauthLoginService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const returnUrl = state.url;
        const permissionData = route.data[this.permissionObjectKey] as IGuardPermission;
        return this.hasAuthUserAccessToThisRoute(permissionData, returnUrl);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const returnUrl = state.url;
        const permissionData = childRoute.data[this.permissionObjectKey] as IGuardPermission;

        const dynamicMenu = childRoute.data[this.dynamicObjectKey] as boolean;


        if (dynamicMenu) {
            const urlSegments = state.url.split('/');
            const guid = urlSegments.length > 2 ? urlSegments[2] : null; // 1. segmenti al
    
            if (guid) {
                permissionData.menuId = guid;
            }
        }

        return this.hasAuthUserAccessToThisRoute(permissionData, returnUrl);
    }

    canLoad(route: Route): boolean {
        if (route.data) {
            const permissionData = route.data[this.permissionObjectKey] as IGuardPermission;
            const returnUrl = `/${route.path}`;
            return this.hasAuthUserAccessToThisRoute(permissionData, returnUrl);
        } else {
            return true;
        }
    }

    private hasAuthUserAccessToThisRoute(permissionData: IGuardPermission, returnUrl: string): boolean {

        if (!this.authService.isUserLoggedIn()) {
            this.redirectToLogin(returnUrl);
            return false;
        }

        if (!permissionData) {
            return true;
        }

        if (Array.isArray(permissionData.deniedRoles) && Array.isArray(permissionData.permittedRoles)) {
            throw new Error('Don\'t set both \'deniedRoles\' and \'permittedRoles\' in route data.');
        }


        const permitted = this.authService.isAuthenticatedMenu(permissionData.menuId);
        if (permitted) {
            return true;
        }

        this.redirectToAccessDenied(returnUrl);

        return false;
    }

    private redirectToLogin(returnUrl: string): void {
        this.router.navigate(['/auth'], { queryParams: { returnUrl: returnUrl } });
    }

    //redirect to access denied page
    private redirectToAccessDenied(returnUrl: string): void {
        this.router.navigate(['/access-denid?1'], { queryParams: { returnUrl: returnUrl } });
    }




}
