import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionStageBadgeComponent } from './version-stage-badge.component';

@NgModule({
    declarations: [VersionStageBadgeComponent],
    imports: [
        CommonModule
    ],
    exports: [VersionStageBadgeComponent]
})
export class VersionStageBadgeModule { }
