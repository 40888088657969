import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Observable, take, timer, map } from 'rxjs';

@Component({
  selector: 'update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss'],
  host: {
    class: 'absolute inset-0 ',
    style: 'z-index: 999999999999 !important'
  }
})
export class UpdateModalComponent implements OnInit {

  @Input() countdownSeconds = 10;
  @Output() countdownFinished = new EventEmitter<void>();

  countdown$: Observable<number>;
  currentCountdown: number | null = null;

  constructor() { }
  ngOnInit() {
    this.countdown$ = timer(0, 1000).pipe(
      take(this.countdownSeconds + 1),
      map(seconds => this.countdownSeconds - seconds)
    );

    this.countdown$.subscribe(countdown => {
      this.currentCountdown = countdown;
      if (countdown === 0) {
        this.countdownFinished.emit();
      }
    });
  }
}
