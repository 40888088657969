import { Injectable } from '@angular/core';
import { LiveTrackerSettings as LiveTrackerSettings } from '../interfaces/ILiveTrackerSettings';
import { environment } from '@env/environment';
import { ApplicationSettings } from '@app/shared/system/application-settings';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {

  public app: ApplicationSettings = new ApplicationSettings();
  public tracker: LiveTrackerSettings = new LiveTrackerSettings();
  public navizardSystemMenu: any | null = null;


  public siteAddres: string;
  public apiAddress: string;
  public apiV2Address: string;
  public photoServerAddress: string;
  public photoServerApiAddress: string;

  
  public isDebugMode: boolean = false;

  constructor() {

    this.siteAddres = environment.site;
    this.apiAddress = environment.api;
    this.apiV2Address = environment.apiv2;

  }


}
