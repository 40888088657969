import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateNewTagComponent } from './create-new-tag.component';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@app/material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { FuseCardModule } from '@fuse/components/card';

@NgModule({
    declarations: [CreateNewTagComponent],
    imports: [
        CommonModule,
        SharedModule,
        TranslateModule.forChild(),

        NgxMatSelectSearchModule,

        NgSelectModule,
        NgOptionHighlightModule,

        NgSelectModule,
        FuseCardModule,

        MaterialModule,

    ],
    exports: [CreateNewTagComponent]
})
export class CreateNewTagModule { }
