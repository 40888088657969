import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Guid } from '@app/core/constants/constants';
import { environment } from '@env/environment';
import { Observable, map, retry, catchError, throwError } from 'rxjs';
import { appInjector } from '../classes/app-injector';
import { OperationResult } from './models/operation-result';
import { DataService } from '@app/core/services/data.service';
import { PhotoRelation } from '@app/core/constants/global-enum';
import { GlobalVariablesService } from '@app/core/services/global-variables.service';
@Injectable({
  providedIn: 'root'
})
export class ImageService {
  public operationCompleted: EventEmitter<OperationResult<Guid>> = new EventEmitter();

  public httpClient: HttpClient;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private dataService: DataService, private globals: GlobalVariablesService) {
    this.httpClient = appInjector.get(HttpClient);
  }

  public getImageUrl(imageId: Guid, aiCrop: number = 0.8, cacheId: Guid = Guid.newGuid(), width: number = 256): string {
    if (aiCrop == 0)
      return `${this.globals.photoServerAddress}/content/images/${imageId}/original.jpg?width=${width}&ci=${cacheId}`;
    return `${this.globals.photoServerAddress}/content/images/${imageId}/original.jpg?width=${width}&content-aware=${aiCrop}&ci=${cacheId}`;
  }

  public keepImage(imageId: Guid): Observable<void> {
    return this.httpClient.put<void>(`${this.globals.photoServerApiAddress}/image/${imageId}/keep`, this.httpOptions)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(3),
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = ` ${error.status}, ` + `message: ${error.message}`;
    }

    return throwError(() => error);
  }

  temporaryTrackItemImage(imageUrl: string): string {
    return this.dataService.photoLink(imageUrl, PhotoRelation.TrackItem);
  }


  public imageError(event: any): void {
    event.target.src = 'assets/images/placeholders/placeholder-large.png';
}

}
