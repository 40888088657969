import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';

import { LanguageService } from '@app/shared/services/language.service';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'languages'
})
export class LanguagesComponent implements OnInit, OnDestroy {

    constructor(public languageService: LanguageService) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {

    }

}
