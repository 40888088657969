/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Injector } from '@angular/core';

export function setAppInjector(injector: Injector) {
    if (appInjector) {
        console.error('Programming error: AppInjector was already set');
    } else {
        appInjector = injector;
    }
}

export let appInjector: Injector;
