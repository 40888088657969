export interface TagPropertyItemHistoryCache {
    tagPropertyId: string;
    tagTypeId: string;
    tagPropertyTemplateId: string;
    value?: string;
    booleanValue?: boolean;
    numberValue?: number;
    dateTimeValue?: Date;
    readAt: Date;
    updateRequired: boolean;
    keepHistory: boolean;
    querySelector: string;
 
}

export class TagPropertyWrapper {
    tagId: string;
    tagValue: string;
    tagTypeId: string;
    tagSessionHistoryId: string;
    properties: TagPropertyItemHistoryCache[] = [];

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
