import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import {
    feather,
    fontawesome,
    heroicons,
    iconsmind,
    material,
} from 'app/mock-api/ui/icons/data';

@Injectable({
    providedIn: 'root',
})
export class IconsMockApi {
    private readonly _feather: any = feather;
    private readonly _heroicons: any = heroicons;
    private readonly _iconsmind: any = iconsmind;
    private readonly _material: any = material;

    private readonly _fontawesome: any = fontawesome;

    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    registerHandlers(): void {

        this._fuseMockApiService
            .onGet('api/ui/icons/fontawesome-light')
            .reply(() => [
                200,
                {
                    namespace: 'fontawesome_light',
                    name: 'Fontawesome Light',
                    grid: 6,
                    list: cloneDeep(this._fontawesome),
                },
            ]);

        this._fuseMockApiService.onGet('api/ui/icons/feather').reply(() => [
            200,
            {
                namespace: 'feather',
                name: 'Feather',
                grid: 6,
                list: cloneDeep(this._feather),
            },
        ]);

        this._fuseMockApiService
            .onGet('api/ui/icons/heroicons-outline')
            .reply(() => [
                200,
                {
                    namespace: 'heroicons_outline',
                    name: 'Heroicons Outline',
                    grid: 6,
                    list: cloneDeep(this._heroicons),
                },
            ]);

        this._fuseMockApiService
            .onGet('api/ui/icons/heroicons-solid')
            .reply(() => [
                200,
                {
                    namespace: 'heroicons_solid',
                    name: 'Heroicons Solid',
                    grid: 5,
                    list: cloneDeep(this._heroicons),
                },
            ]);

        this._fuseMockApiService.onGet('api/ui/icons/iconsmind').reply(() => [
            200,
            {
                namespace: 'iconsmind',
                name: 'Iconsmind',
                grid: 10,
                list: cloneDeep(this._iconsmind),
            },
        ]);

        this._fuseMockApiService
            .onGet('api/ui/icons/material-solid')
            .reply(() => [
                200,
                {
                    namespace: 'mat_solid',
                    name: 'Material Solid',
                    grid: 6,
                    list: cloneDeep(this._material),
                },
            ]);

        this._fuseMockApiService
            .onGet('api/ui/icons/material-outline')
            .reply(() => [
                200,
                {
                    namespace: 'mat_outline',
                    name: 'Material Outline',
                    grid: 6,
                    list: cloneDeep(this._material),
                },
            ]);

        this._fuseMockApiService
            .onGet('api/ui/icons/material-twotone')
            .reply(() => [
                200,
                {
                    namespace: '',
                    name: 'Material Twotone',
                    grid: 6,
                    list: cloneDeep(this._material),
                },
            ]);
    }
}
