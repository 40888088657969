import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  private alarmAudio = new Audio('assets/sounds/alarm.mp3');
  private messageAudio = new Audio('assets/sounds/message.mp3');

  constructor() {}

  playSound(soundType: 'alarm' | 'message', playCount: number = 1) {
    this.stopAllSounds();

    let audio;
    if (soundType === 'alarm') {
      audio = this.alarmAudio;
    } else if (soundType === 'message') {
      audio = this.messageAudio;
    }

    if (playCount <= 0) {
      // Loop modu
      audio.loop = true;
      audio.play();
    } else {
      // Belirli sayıda çal
      audio.loop = false;
      this.playAudioMultipleTimes(audio, playCount);
    }
  }

  private playAudioMultipleTimes(audio: HTMLAudioElement, playCount: number) {
    let counter = 0;
    audio.play();

    audio.onended = () => {
      counter++;
      if (counter < playCount) {
        audio.currentTime = 0;
        audio.play();
      }
    };
  }

  private stopAllSounds() {
    [this.alarmAudio, this.messageAudio].forEach(audio => {
      audio.pause();
      audio.currentTime = 0;
      audio.loop = false;
    });
  }
}
