import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavizardVersioningComponent } from './navizard-versioning.component';

@NgModule({
  declarations: [
    NavizardVersioningComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [NavizardVersioningComponent]
})
export class NavizardVersioningModule {
  /**
   *
   */
  
}
