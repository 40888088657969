import { LOCALE_ID, Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


export class LocaleId extends String {
  constructor(private translateService: TranslateService) {
    super();
  }

  toString(): string {

    return this.translateService.currentLang;
  }

  valueOf(): string {
    return this.toString();
  }
}

export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [TranslateService],
};