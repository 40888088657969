import { Injectable } from '@angular/core';
import { BaseHubConnection } from './base-hub-connection.service'; // BaseHubConnection sınıfının yolu
import { DeviceModel } from './models/device.model';
import { BehaviorSubject } from 'rxjs';
import { GlobalVariablesService } from '../global-variables.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Represents a hub connection service for devices.
 */
export class DeviceHubConnectionService extends BaseHubConnection {
  
  private deviceUpdatedSubject: BehaviorSubject<DeviceModel> = new BehaviorSubject<DeviceModel>(null);
  public deviceUpdated$ = this.deviceUpdatedSubject.asObservable();

  constructor(private globals: GlobalVariablesService) {
    super();
  
   

  }

  public init() {
    this.initializeHubConnection(`${this.globals.tracker.address}/device-hub`);
    this.connectionStatus.subscribe((status) => {
      console.log('DeviceHubConnectionService status:', status);
    }
    );

    this.hubConnection.on("DeviceUpdated", (data: any) => {
      const device = new DeviceModel(data);
      this.deviceUpdatedSubject.next(device);
    });
  }
}