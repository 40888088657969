<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && authUser.avatar" [src]="photoLink()"
            (error)="errorHandler($event)">
        <fa-icon *ngIf="!showAvatar || !authUser.avatar" [icon]="['fal', 'user-circle']" class="icon-size-5"></fa-icon>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span class="mt-1.5 text-md font-medium">{{authUser?.displayName}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="changePassword()">
        <fa-icon [icon]="['fal', 'user-circle']" class="icon-size-4 mr-1"></fa-icon>
        <span>{{'SECURITY.CHANGE_PASSWORD' | translate}}</span>
    </button>

    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <fa-icon [icon]="['fal', 'sign-out']" class="icon-size-4 mr-1"></fa-icon>
        <span>
            {{'SECURITY.SIGN_OUT' | translate}}
        </span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>