import { SettingsService } from './../../../core/services/settings/settings.service';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, concatMap, from, map, switchMap, take, tap, toArray } from 'rxjs';
import { GlobalVariablesService } from '@app/core/services/global-variables.service';
import { SoundService } from '@app/shared/services/sound.service';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { AlertValidationResult, TagAlert } from '@app/core/services/hubs/models/tag-alert.model';
import { cloneDeep } from 'lodash';
import { PostOfficeService } from '@app/core/services/post-office/post-office.service';
import { SecurityAlertComponent } from '@app/shared/toasts/security-alert/security-alert.component';
import { TranslateService } from '@ngx-translate/core';
import { SecurityAlert } from '@app/shared/toasts/security-alert/models/security-alert';
import { ObjectSafetyDataService } from '@app/pages/object-safeties/services/object-safety-data.service';


// notifications-data.model.ts
export class NotificationsData {
  constructor(
    public totalPages: number,
    public pageSize: number,
    public currentPage: number,
    public totalMessages: number,
    public unreadMessages: number
  ) { }
}


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly STORAGE_KEY = 'app_notifications';
  private readonly TOKEN_KEY = 'fcm_token';
  private readonly SUBSCRIBED_TOPICS_KEY = 'subscribed_topics';
  private readonly PAGE_SIZE = 3;
  private currentPage = 1;

  private token$ = new BehaviorSubject<string | null>(this.getTokenFromLocalStorage());
  private subscribedTopics$ = new BehaviorSubject<Set<string>>(this.getSubscribedTopicsFromLocalStorage());
  private allMessages$ = new BehaviorSubject<any[]>([]);
  private paginatedMessages$ = new BehaviorSubject<any[]>([]);
  private notificationsData$ = new BehaviorSubject<NotificationsData>(new NotificationsData(0, this.PAGE_SIZE, 1, 0, 0));

  constructor(
    private globals: GlobalVariablesService,

    private http: HttpClient,
    private soundService: SoundService,
    public toastr: ToastrService,
    private postOfficeService: PostOfficeService,
    private objectSafetyService: ObjectSafetyDataService,
    private translateService: TranslateService,
    private afMessaging: AngularFireMessaging,
    private settingsService:SettingsService

  ) {
    this.loadAllMessages();
    this.listenToMessages();
    this.updateNotificationsData();
    // afMessaging.onBackgroundMessage((message) => {
    //   console.log('onBackgroundMessage', message);
    //   // this.showAlert(message.data);
    // });

  }

  public requestPermission(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          this.afMessaging.getToken.subscribe({
            next: (token) => {
              console.log("FCM Token:", token);
              this.updateToken(token);
              observer.next(true);
              observer.complete();
            },
            error: (tokenError) => {
              console.error("FCM Token alma hatası:", tokenError);
              observer.error(tokenError);
            }
          });
        } else {
          console.error("Bildirim izni reddedildi.");
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  public handleTokenRefresh(): void {
    this.afMessaging.tokenChanges.subscribe({
      next: (newToken) => {
        const oldToken = this.getTokenFromLocalStorage();
        if (newToken === null || newToken === oldToken) return;

        //this.toastr.info(`Token refreshed`, "Notification", { timeOut: 100000 });

        console.log("New FCM token: ", newToken);
        this.updateToken(newToken, oldToken);

        this.reSubscribeToTopics(newToken);
      },
      error: (err) => console.error("Token refresh error: ", err)
    });
  }

  private reSubscribeToTopics(newToken: string): void {
    const subscribedTopicsArray = Array.from(this.subscribedTopics$.value);

    // İlk olarak tüm topic'ler için unsubscribe işlemi
    from(subscribedTopicsArray).pipe(
      concatMap(topic => this.unsubscribeToTopic(topic)),
      toArray() // Tüm unsubscribes işlemlerinin tamamlanmasını bekler
    ).subscribe({
      next: () => {
        console.log('All topics unsubscribed successfully.');

        // Unsubscribe işlemi tamamlandıktan sonra, subscribe işlemlerini başlat
        from(subscribedTopicsArray).pipe(
          concatMap(topic => this.subscribeToTopic(topic))
        ).subscribe({
          next: response => console.log('Subscription successful:', response),
          error: error => console.error('Subscription failed:', error)
        });
      },
      error: error => console.error('Unsubscription failed:', error)
    });
  }



  private updateToken(newToken: string | null, oldToken: string | null = null): void {
    // this.toastr.info("Token updated", "Notification", { timeOut: 10000 });
    if (newToken) {
      this.sendTokenToServer(newToken, oldToken);
      localStorage.setItem(this.TOKEN_KEY, newToken);
    } else {
      localStorage.removeItem(this.TOKEN_KEY);
    }
    this.token$.next(newToken);
  }

  private sendTokenToServer(newToken: string, oldToken: string | null): void {
    const url = `${this.globals.apiV2Address}/push-tokens`;
    const body = { newToken: newToken, oldToken: oldToken };
    this.http.put(url, body).subscribe(
      (res) => console.log('Token sunucuya başarıyla gönderildi:', res),
      (err) => console.error('Token sunucuya gönderilirken hata oluştu:', err)
    );
  }

  private getTokenFromLocalStorage(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  private getSubscribedTopicsFromLocalStorage(): Set<string> {
    const storedTopics = localStorage.getItem(this.SUBSCRIBED_TOPICS_KEY);

    return new Set(storedTopics ? JSON.parse(storedTopics) : []);
  }

  public subscribeToTopic(topic: string): Observable<any> {
    const url = `${this.globals.tracker.address}/subscriptions/subscribe`;

    return this.token$.pipe(
      take(1),
      switchMap(token => {
        if (!token) {
          throw new Error('FCM token yok. Topiğe abone olunamadı.');
        }
        const body = { topics: [topic], token: token };
        return this.http.post(url, body).pipe(
          tap(res => {
            this.toastr.success("Subscribed to " + topic, "Notification");
            console.log('Topiğe abone olma başarılı:', res);
            this.updateSubscribedTopics(new Set([...this.subscribedTopics$.value, topic]));
          })
        );
      })
    );
  }


  public unsubscribeToTopic(topic: string): Observable<any> {
    const url = `${this.globals.tracker.address}/subscriptions/unsubscribe`;

    return this.token$.pipe(
      take(1),
      switchMap(token => {
        if (!token) {
          throw new Error('FCM token yok. Topiğe abonelikten çıkılamadı.');
        }
        const body = { topics: [topic], token: token };
        return this.http.post(url, body).pipe(
          tap(res => {
            console.log('Topiğe abonelikten çıkıldı:', res);
            this.updateSubscribedTopics(new Set([...this.subscribedTopics$.value].filter(t => t !== topic)));
          })
        );
      })
    );
  }


  public cancelSubscription(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const url = `${this.globals.tracker.address}/subscriptions/cancel-subscription`;
      this.token$.pipe(take(1)).subscribe(token => {
        if (token) {
          const body = { token: token };
          this.http.post(url, body).subscribe(
            (res) => {
              console.log('Unsubscribed from all topics:', res);
              this.updateSubscribedTopics(new Set<string>()); // Local storage güncellenir
              this.updateToken(null);
              observer.next(true);
              observer.complete();
            },
            (err) => {
              console.error('Error unsubscribing from all topics:', err);
              observer.next(false);
              observer.complete();
            }
          );
        } else {
          console.error('FCM token yok. Tüm konulardan çıkış yapılamadı.');
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  private updateSubscribedTopics(topics: Set<string>): void {
    localStorage.setItem(this.SUBSCRIBED_TOPICS_KEY, JSON.stringify(Array.from(topics)));

    this.subscribedTopics$.next(topics);
  }

  public isSubscribedToTopic(topic: string): Observable<boolean> {

    return this.subscribedTopics$.asObservable().pipe(map(topics => topics.has(topic)));
  }

  public isSubscribed(): Observable<boolean> {
    return this.token$.asObservable().pipe(map(token => token !== null));
  }




  private updatePaginatedMessages(): void {
    const sortedMessages = this.sortMessagesByReadStatus(this.allMessages$.value);
    const startIndex = (this.currentPage - 1) * this.PAGE_SIZE;
    const endIndex = startIndex + this.PAGE_SIZE;
    const paginatedMessages = sortedMessages.slice(startIndex, endIndex);
    this.paginatedMessages$.next(paginatedMessages);
  }

  private sortMessagesByReadStatus(messages: any[]): any[] {
    return messages.sort((a, b) => {
      if (a.read === b.read) {
        return 0; // Eğer ikisi de okunmuş veya okunmamışsa sıralamayı değiştirme
      }
      return a.read ? 1 : -1; // Okunmamış mesajlar önce, okunanlar sonra
    });
  }

  public getPaginatedMessages(): Observable<any[]> {
    return this.paginatedMessages$.asObservable();
  }


  public setPage(newPage: number): void {
    this.currentPage = newPage;
    this.updatePaginatedMessages(); // Güncellemeyi burada yap
    this.updateNotificationsData();
  }

  private updateNotificationsData(): void {
    const totalMessages = this.allMessages$.value.length;
    const unreadMessages = this.allMessages$.value.filter(message => !message.read).length;
    const totalPages = Math.ceil(totalMessages / this.PAGE_SIZE);
    const notificationsData = new NotificationsData(totalPages, this.PAGE_SIZE, this.currentPage, totalMessages, unreadMessages);
    this.notificationsData$.next(notificationsData);
  }


  public getNotificationsData(): Observable<NotificationsData> {
    return this.notificationsData$.asObservable();
  }

  public addNewMessage(message: any): void {
    const updatedAllMessages = [message, ...this.allMessages$.value];
    this.allMessages$.next(updatedAllMessages);
    this.saveAllMessages(updatedAllMessages);
    this.updatePaginatedMessages(); // Güncellemeyi burada yap
    this.updateNotificationsData();
  }

  public deleteMessage(index: number): void {
    const updatedAllMessages = [...this.allMessages$.value];
    updatedAllMessages.splice(index, 1);
    this.allMessages$.next(updatedAllMessages);
    this.saveAllMessages(updatedAllMessages);
    this.updatePaginatedMessages(); // Güncellemeyi burada yap
    this.updateNotificationsData();
  }

  public clearMessages(): void {
    this.allMessages$.next([]);
    this.saveAllMessages([]);
    this.updatePaginatedMessages(); // Güncellemeyi burada yap
    this.updateNotificationsData();
  }

  private listenToMessages(): void {
    this.afMessaging.messages.subscribe(message => {
      const newMessage = { ...message, timestamp: new Date().toISOString(), read: false, isPush: true };
      this.addNewMessage(newMessage);

      if (newMessage.data?.NotificationType === "Alarm") {
        this.soundService.playSound('alarm', 5);
      }

    });
  }

  private saveAllMessages(messages: any[]): void {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(messages));
  }

  private loadAllMessages(): void {
    const storedMessages = localStorage.getItem(this.STORAGE_KEY);
    if (storedMessages) {
      const messages = JSON.parse(storedMessages);
      this.allMessages$.next(messages);
      this.updatePaginatedMessages();
    }
  }


  public markMessageAsRead(index: number): void {
    const messages = this.allMessages$.value;
    if (messages[index] && !messages[index].read) {
      messages[index].read = true;
      this.allMessages$.next([...messages]);
      this.saveAllMessages(messages);
      this.updatePaginatedMessages(); // Güncellemeyi burada yap
    }
  }



  async showAlert(tagAlert: TagAlert) {

    var tag = await this.postOfficeService.getTag(tagAlert.tagId,undefined);

    if (tag.trackItemId == null)
      return;

    var trackItem = await this.postOfficeService.getTrackItem(tag.trackItemId);


    var infantTagTypeId = this.settingsService.getSettingValue('Infant.Baby.Tag.Type');

    let isInfantTag = tag.tagTypeId == infantTagTypeId;

    let options: GlobalConfig;
    options = this.toastr.toastrConfig;
    const opt = cloneDeep(options);
    opt.toastComponent = SecurityAlertComponent;
    opt.toastClass = 'toast';
    opt.enableHtml = false;
    opt.disableTimeOut = false;
    opt.timeOut = 10000;
    opt.tapToDismiss = true;


    const sa = new SecurityAlert();
    sa.date = tagAlert.readAt;
    sa.photoUrl = trackItem.photoUrl;

    sa.locateUrl = '/live';
    sa.locateUrlParams = { locate: 'tag', id: tagAlert.tagSessionHistoryId }


    sa.title = tagAlert.alert == AlertValidationResult.Alert ? this.translateService.instant('SystemAdmin.Alerts.SecurityAlert.Title') : this.translateService.instant('SystemAdmin.Alerts.SecurityAlert.Resolved.Title');
    sa.alert = tagAlert.alert == AlertValidationResult.Alert;
   
    if (isInfantTag) {
      sa.detailsUrl = `/object-safeties`;
      sa.detailsUrlParams = { 'child-tag': tagAlert.tagId }
    }

    const message = this.translateService.instant(tagAlert.alert == AlertValidationResult.Alert ? 'SystemAdmin.Alerts.SecurityAlert.SensorDescription' : 'SystemAdmin.Alerts.SecurityAlert.Resolved.SensorDescription', {
      sensorProperty: tagAlert.propertyName,
      trackItemDescription: trackItem.description,
      tagName: tag.name
    });

    sa.content = `
   <p>${message}</p>
   `;

    this.toastr.show(JSON.stringify(sa), '', opt);
  }


}
