import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { RequestBase } from './models/request-base';
import { Guid } from '@app/core/constants/constants';

export class PageBaseService<T extends RequestBase> extends BaseHttpService<T> {
  public form: UntypedFormGroup;

  public dataSource: any;

  constructor(
    public url: string,
    public endpoint: string,) {
    super(url, endpoint);
  }

  public initDataSource(idColumnName: string = "id", userData: any = null) {
    this.dataSource = this.getDataSource(idColumnName, userData);
  }


  public hasError(controlName: string, errorName: string, formGroup: string = "edit"): any {
    return this.form.controls[formGroup]['controls'][controlName]?.hasError(errorName);
  }



  public markFormControlsAsTouched(formGroup?: FormGroup | FormArray) {
    const group = formGroup || this.form;
    Object.keys(group.controls).forEach(controlName => {
      const control = group.controls[controlName];
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormControlsAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  
  public findAllInvalidControls(): string[] {
    const invalid: string[] = [];

    const findInvalidControls = (formGroup: any, path: string) => {
      const controls = formGroup.controls;
      for (const name in controls) {
        const control = controls[name];
        const currentPath = path ? `${path}.${name}` : name;
        if (control.invalid) {
          invalid.push(currentPath);
        }
        if (control.controls) { // Nested form group or form array
          findInvalidControls(control, currentPath);
        }
      }
    };

    findInvalidControls(this.form, '');

    return invalid;
  }
}
