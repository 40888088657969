export enum DeviceType {

    BeaconReader = 0,
    WiegandReader = 1

}

export enum OnlineStatus {
    Offline,

    Online
}

export enum RebootReason {
    Unknown,

    ManualReset,

    VoltageFailure,

    WatchDog,

    FirmwareUpdate,

    Inactivity,

    Maintenance,

}

export enum HardwareVersion {
    Standard = 0,
    CoreM1 = 2,
    MobileDevice = 3
}

export class DeviceOnlineModel {
    RestartReasonC0: RebootReason;
    RestartReasonC1: RebootReason;
    FirmwareVersion: string;
    IdfVersion: string;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }

    toString(): string {
        return `DeviceOnlineModel: { RestartReasonC0: ${this.RestartReasonC0}, RestartReasonC1: ${this.RestartReasonC1}, FirmwareVersion: ${this.FirmwareVersion}, IdfVersion: ${this.IdfVersion} }`;
    }
}



export interface DeviceDriverModel {
    name: string
    active: boolean;
    running: boolean;
}


export class DeviceModel {
    DeviceType: DeviceType;
    HubId: string;
    HubIp: string;
    HubName: string;
    Type: string;
    Longitude: string;
    Latitude: string;
    Status: OnlineStatus;
    Signal: number;
    CpuTemperature: number;
    Voltage: number;
    Detail: DeviceOnlineModel;
    EventDate: Date = new Date();
    SSID: string;
    BSSID: string;
    RebootReason: RebootReason;
    Uptime: number;
    ScanDriver: string;
    Drivers: DeviceDriverModel[] = [];
    HardwareVersion: HardwareVersion;
    ConfigurationKey: string;
    TargetBSSID: string;
    Message: string;
    StationCount: number;
    HeapSize: number;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
            this.EventDate = new Date(data.EventDate);
            // Diğer özellikler için de benzer dönüşümleri yapabilirsiniz
        }
    }

    toString(): string {
        return `DeviceModel: { DeviceType: ${this.DeviceType}, HubId: ${this.HubId}, HubIp: ${this.HubIp}, ... }`; // Diğer özellikleri de ekleyin
    }
}
