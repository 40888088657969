export class Background {
    index: number;
    image: string;
    bgColor: string;
    fgColor: string;
    class: string;

    constructor(index: number, image: string, bgColor: string, fgColor: string, classString: string) {
        this.index = index;
        this.image = image;
        this.bgColor = bgColor;
        this.fgColor = fgColor;
        this.class = classString;
    }
}