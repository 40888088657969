<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<classic-layout *ngIf="layout === 'classic'"></classic-layout>

<classy-layout *ngIf="layout === 'classy'"></classy-layout>

<compact-layout *ngIf="layout === 'compact'"></compact-layout>

<modern-layout *ngIf="layout === 'modern'"></modern-layout>

<!-- <settings></settings> -->
