import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { FuseFullscreenModule } from '@fuse/components/fullscreen/fullscreen.module';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { SearchModule } from 'app/layout/common/search/search.module';

import { FuseLoadingBarModule } from '@fuse/components/loading-bar';

import { SharedModule } from 'app/shared/shared.module';
import { ClassyLayoutComponent } from 'app/layout/layouts/vertical/classy/classy.component';
import { ActiveTagsModule } from '@app/modules/active-tag/active-tags.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserProfileModule } from '@app/modules/login/profile/user-profile.module';
import { NavizardVersioningModule } from "../../../../shared/components/navizard-versioning/navizard-versioning.module";

@NgModule({
    declarations: [
        ClassyLayoutComponent
    ],
    exports: [
        ClassyLayoutComponent
    ],
    imports: [
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseLoadingBarModule,
        FuseFullscreenModule,
        FuseNavigationModule,
        LanguagesModule,
        ActiveTagsModule,
        SearchModule,
        
        SharedModule,
        UserProfileModule,
        TranslateModule.forChild(),
        NavizardVersioningModule
    ]
})
export class ClassyLayoutModule {
}
