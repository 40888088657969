import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateObjectRelationsComponent } from './create-object-relations.component';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { FuseCardModule } from '@fuse/components/card';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { CreateNewTagModule } from '../create-new-tag/create-new-tag.module';

@NgModule({
    declarations: [CreateObjectRelationsComponent],
    imports: [
        CommonModule,

        ReactiveFormsModule,

        FormsModule,

        RouterModule,

        TranslateModule.forChild(),

        NgxMatSelectSearchModule,

        NgSelectModule,
        NgOptionHighlightModule,

        UiSwitchModule,

        FuseCardModule,

        FontAwesomeModule,

        MaterialModule,

        SharedModule,

        CreateNewTagModule
    ],
    exports: [CreateObjectRelationsComponent]
})
export class CreateObjectRelationsModule { }
