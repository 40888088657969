import { Injectable } from '@angular/core';
import { BaseHubConnection } from './base-hub-connection.service';
import { GlobalVariablesService } from '../global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class SystemHubConnectionService extends BaseHubConnection{

  constructor(private globals: GlobalVariablesService) {
    super();
   
  }

  public init() {
    this.initializeHubConnection(`${this.globals.tracker.address}/system-hub`);
    this.connectionStatus.subscribe((status) => {
      console.log('SystemHubConnectionService status:', status);
    }
    );
  }

}
