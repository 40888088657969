import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Observable, fromEvent, merge } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {
  private onlineStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);

  get isOnline(): Observable<boolean> {
    return this.onlineStatus.asObservable();
  }

  constructor(private swUpdate: SwUpdate) {
    merge(
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    ).subscribe(status => {
      // Service Worker'ın etkin olmadığı durumlarda çevrimiçi olarak varsay
      if (!this.swUpdate.isEnabled) {
        this.onlineStatus.next(true);
      } else {
        this.onlineStatus.next(status);
      }
    });
  }
}
