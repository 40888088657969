import { SettingsService } from '@app/core/services/settings/settings.service';

import { INGXLoggerMonitor, INGXLoggerMetadata, INGXLoggerConfig, NgxLoggerLevel } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { appInjector } from '../classes/app-injector';
export class LoggerMonitor implements INGXLoggerMonitor {
  notifier: ToastrService;
  setting: SettingsService;

  constructor() {
    this.notifier = appInjector.get(ToastrService);
    this.setting = appInjector.get(SettingsService);
  }

  onLog(log: INGXLoggerMetadata, config: INGXLoggerConfig): void {
    if (log.level < NgxLoggerLevel.ERROR) { return; }

    let globalError = false;
    if (log?.additional.length > 0) {
      globalError = Boolean(log?.additional[0].global).valueOf();
    }

    let browserLogs = this.setting.getSettingValue('Performance.Browser.EnableDebug');

    /* Eğer mevcut sayfa yenilendiğinde(F5) bir hata üretirse, henüz settings yüklenmemiş olabilir.
     Bu durumda varsayılan olarak 'browserLogs=true' kabul ediyoruz*/
    browserLogs = browserLogs == null ? true : browserLogs;

    if (globalError && browserLogs) {
      this.notifier.error(log.message);
    } else if (!globalError) {
      this.notifier.error(log.message);
    }
  }
}
