<div class="toast m-2 mr-4 pointer-events-auto cursor-pointer rounded-lg bg" [class.green]="!alert.alert">
    <div class="space-y-5">
        <div
            class=" relative mx-auto w-[350px] max-w-[350px] bg-white/40  backdrop-blur-lg  drop-shadow-md rounded-lg border-none ring-2 ring-white/30  p-4 text-sm shadow-lg">

            <!-- Close Button -->
            <button class="top-3 absolute right-4 ml-auto cursor-pointer text-slate-600 hover:text-white  ">
                <fa-icon [icon]="['fal', 'times']" class="h-5 w-5 text-xl font-bold"></fa-icon>
            </button>


            <div class="flex space-x-4 mr-6">

                <!-- Photo -->
                <div class="flex items-center space-x-4">
                    <div
                        class="relative h-14 w-14 rounded-full border-2 border-white border-opacity-20  overflow-hidden transition-transform duration-300 hover:scale-125 ease-in-out mr-2 ">
                        <img [src]="imageService.temporaryTrackItemImage(alert.photoUrl)"
                            (error)="imageService.imageError($event)"
                            class="object-cover object-center h-full w-full max-w-xs hover:scale-150  origin-center transition duration-1000 ease-in-out z-0">
                    </div>
                </div>


                <div class="flex-1">

                    <!-- Title and Time -->
                    <div class="flex-col items-start space-y-2">
                        <h4 class="font-medium text-slate-900">{{alert.title ? alert.title :'SystemAdmin.Alerts.SecurityAlert.Title' | translate}}</h4>
                        <span class="font-normal text-xs text-slate-500">{{alert.date | relativeTime   }}</span>
                    </div>

                    <!-- Content -->
                    <div class="mt-1 text-slate-800" [innerHTML]="safeHtml">

                    </div>

                    <!-- Button Group -->
                    <div class="mt-2 flex space-x-4">
                        <a [routerLink]="[alert.locateUrl]" [queryParams]="alert.locateUrlParams"
                            class="inline-block font-medium leading-loose text-slate-500 hover:text-slate-900">
                            {{'SystemAdmin.Alerts.SecurityAlert.CTA.Locate' | translate}}
                        </a>
                        <a [routerLink]="[alert.detailsUrl]" [queryParams]="alert.detailsUrlParams" *ngIf="alert.detailsUrl"
                            class="inline-block font-medium leading-loose text-blue-600 hover:text-blue-700">
                            {{'SystemAdmin.Alerts.SecurityAlert.CTA.Details' | translate}}
                        </a>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
