import { NgModule } from '@angular/core';
import { CharactersPipe } from './characters.pipe';
import { SplitcharactersPipe } from './splitcharacters.pipe';
import { FullWordsPipe } from './fullwords.pipe';

@NgModule({
    declarations: [CharactersPipe, SplitcharactersPipe, FullWordsPipe],
    exports: [CharactersPipe, SplitcharactersPipe, FullWordsPipe]
})
export class TruncatePipesModule {

}
