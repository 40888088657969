/* eslint-disable @typescript-eslint/member-ordering */
const isFunction = (fn: any): any => typeof fn === 'function';

export interface SubscriptionLike {
    unsubscribe(): void;
}

export class SubSink {

    protected _subs: SubscriptionLike[] = [];

    /**
     * Subscription sink that holds Observable subscriptions
     * until you call unsubscribe on it in ngOnDestroy.
     *
     * @example
     * In Angular:
     * ```
     *   private subs = new SubSink();
     *   ...
     *   this.subs.sink = observable$.subscribe(
     *   this.subs.add(observable$.subscribe(...));
     *   ...
     *   ngOnDestroy() {
     *     this.subs.unsubscribe();
     *   }
     * ```
     */
    constructor() { }

    add(...subscriptions: SubscriptionLike[]): any {
        this._subs = this._subs.concat(subscriptions);
    }

    set sink(subscription: SubscriptionLike) {
        this._subs.push(subscription);
    }

    unsubscribe(): any {
        this._subs.forEach(sub => sub && isFunction(sub.unsubscribe) && sub.unsubscribe());
        this._subs = [];
    }
}
