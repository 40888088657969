import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, BehaviorSubject, firstValueFrom, of } from 'rxjs';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PageBaseService } from '@app/shared/services/page-base.service';
import { ObjectSafetyDTOs } from '../dtos/object-safety';
import { Guid } from '@app/core/constants/constants';
import { tap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObjectSafetyDataService extends PageBaseService<ObjectSafetyDTOs.ObjectSafetyDTO> {

  public summary: Observable<ObjectSafetyDTOs.ObjectSafetySummary>;
  private simpleSourceSubject = new BehaviorSubject<ObjectSafetyDTOs.ObjectSafetyId[] | null>(null);

  public simpleSource$: Observable<ObjectSafetyDTOs.ObjectSafetyId[]> = this.simpleSourceSubject.asObservable().pipe(
    switchMap(simpleSource => {
      if (simpleSource === null) {
        return this.initSimpleSource().pipe(
          switchMap(() => this.simpleSourceSubject.asObservable())
        );
      } else {
        return of(simpleSource);
      }
    })
  );

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    super(environment.apiv2, 'object-safeties');

    this.form = this.formBuilder.group({
      header: this.formBuilder.group({}),
      edit: this.formBuilder.group({
        id: [],
        name: ['', [Validators.required, Validators.maxLength(64)]],
      })
    });
  }

  private initSimpleSource(): Observable<void> {
    if (this.simpleSourceSubject.getValue() === null) {
      return this.getSimple<ObjectSafetyDTOs.ObjectSafetyId[]>().pipe(
        tap(objectSafeties => this.simpleSourceSubject.next(objectSafeties)),
        switchMap(() => of(void 0))
      );
    }
    return of(void 0);
  }

  public initSummary(id: string | Guid): Observable<void> {
    this.summary = this.fetchSummary<ObjectSafetyDTOs.ObjectSafetySummary>(id);
    return this.summary.pipe(
      switchMap(() => of(void 0))
    );
  }

  public async getByChilTagId<T>(id: Guid): Promise<T> {
    const url = `${this.url}/${this.endpoint}/child-tag/${id}`;
    return firstValueFrom(this.httpClient.get<T>(url));
  }
}