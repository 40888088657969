import { LiveCacheDTOs } from '../dto/live-cache-dto';

export namespace PostOfficeModels {

    export class TagInfoModel {
        liveLocation: LiveCacheDTOs.LiveLocation;
        trackItem: LiveCacheDTOs.TrackItem;
        tag: LiveCacheDTOs.Tag;
        tagProperties: LiveCacheDTOs.TagProperty[];


        isOnline: boolean;

        constructor() {

            this.checkOnlineStatus();
            setInterval(() => this.checkOnlineStatus(), 3000);
        }

        private checkOnlineStatus() {
            if (!this.liveLocation || !this.liveLocation.lastSeenAt) {
              this.isOnline = false;
              return;
            }
      
            const lastSeenUTC = new Date(this.liveLocation.lastSeenAt);
            const now = new Date();
            const diffInSeconds = (now.getTime() - lastSeenUTC.getTime()) / 1000;
      
            this.isOnline = diffInSeconds <= 30;
          }
    }
}