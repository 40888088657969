import { Injectable } from '@angular/core';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { BadisOauthLoginService } from './auth/badis-oauth-login.service';
import { CustomEventService } from './custom-event.service';
import { DataService } from './data.service';
import { GlobalVariablesService } from './global-variables.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomReportService } from './custom-report.service'; // Add this import
import { CustomReport } from '@app/shared/classes/custom-reports.model';

@Injectable()
export class SystemMenuService {

    private navComponent;
    private dynamicUserMenuClaims: any;
    private newNavigation: FuseNavigationItem[];
    public customReports: CustomReport[] = []; // Custom reports array

    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private _dataService: DataService,
        private _badisOauthLoginService: BadisOauthLoginService,
        private customEventService: CustomEventService,
        private varService: GlobalVariablesService,
        private customReportService: CustomReportService,
    ) {
        this.customEventService.on('object-types-updated').subscribe(() => this.reloadSystemMenu());
    }

    public populateSystemMenu(): void {

        const newSystemMenu: any = this.varService.navizardSystemMenu;

        if (newSystemMenu === null || newSystemMenu === undefined) { return; }

        this.navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        if (!this.navComponent) {
            return;
        }

        this.newNavigation = newSystemMenu;
        this.navComponent.navigation = this.newNavigation;

        this.dynamicUserMenuClaims = this._badisOauthLoginService.userAccessToken.dynamicUserMenuClaims;

        this.reloadSystemMenu();

        this.newNavigation.forEach((menuItem: any) => {
            menuItem.hidden = menuItem.hide || (menuItem.roles && menuItem.roles.length > 0 &&
                !this._badisOauthLoginService.isUserInRoles(menuItem.roles) &&
                !this.userHasNoDynaminMenuClaims(menuItem)
            );

            // also apply for child items
            if (menuItem.children) {
                menuItem.children.forEach((childItem: any) => {
                    const isInRoles = !this._badisOauthLoginService.isUserInRoles(childItem.roles);
                    const isInClaims = !this.userHasNoDynaminMenuClaims(childItem.id);
                    childItem.hidden = childItem.hide || (childItem.roles && childItem.roles.length > 0 &&
                        isInRoles &&
                        isInClaims
                    );
                });
            }
        });

        this.navComponent.refresh();
    }

    protected reloadSystemMenu(): void {
        const customObjectTypeMenu: FuseNavigationItem = this._fuseNavigationService.getItem('definitions-tracking-item-types', this.navComponent.navigation);

        if (customObjectTypeMenu) {
            this.getCustomObjectTypeMenu().subscribe((response): void => {
                customObjectTypeMenu.children = response.map((menu) => {
                    let iconWithNameSpace = 'fal:times-hexagon';

                    try {
                        if (menu?.icon) {
                            const iconConfig = `${menu.icon}`.replace(/'/g, '"');

                            if (iconConfig) {
                                const icon = JSON.parse(iconConfig);

                                if (icon) { iconWithNameSpace = `${icon[0]}:${icon[1]}`; }
                            }
                        }
                    }
                    catch { }

                    return {
                        id: menu.id,
                        title: menu.name,
                        link: `object-item-tracking/${menu.id}`,
                        icon: iconWithNameSpace,
                        hidden: !this.userHasNoDynaminMenuClaims(menu.id),
                        badge: {
                            title: menu.objectsCount,
                            classes: 'px-2 bg-gray-600 text-white rounded-full'
                        },
                        type: 'basic'
                    };
                });

                console.log('customObjectTypeMenu', customObjectTypeMenu);
            });
        }

        this.refreshCustomReportsMenu();
    }

    private getCustomObjectTypeMenu(): any {
        return this._dataService.getAny<any>('track-item-type/active');
    }

    private userHasNoDynaminMenuClaims(id: string): boolean {
        return this.dynamicUserMenuClaims?.includes(id);
    }

    // Refactored metod: Kullanıcıya özel raporları çekmek için
    private getUserCustomReports(): Observable<CustomReport[]> {
        return this.customReportService.getUserCustomReports();
    }

    // Custom reports menu refresh function
    public refreshCustomReportsMenu(): void {
        this.getUserCustomReports().subscribe((reports: CustomReport[]): void => {
            this.customReports = reports; // Store reports in customReports array
            if (reports.length <= 0) return;
            const reportsMenu = this._fuseNavigationService.getItem('reports', this.navComponent.navigation);

            if (reportsMenu) {
                // Önceki raporları kaldır
                reportsMenu.children = reportsMenu.children?.filter(child => child.id !== 'custom-reports') || [];

                const customReportsMenu: FuseNavigationItem = {
                    id: 'custom-reports',
                    title: 'SystemAdmin.Menu.Reports.CustomReports.Title',
                    icon: 'fal:file-chart-column',
                    type: 'collapsable',
                    classes: { wrapper: 'text-yellow-500' },
                    children: reports
                        .sort((a, b) => a.order - b.order) // Sort reports by order
                        .map((report) => {
                            let link: string;
                            switch (report.groupKey) {
                                case 'Sensor':
                                    link = `/live-sensors/?cr=${report.id}`;
                                    break;
                                case 'Location':
                                    link = `/live-tag-locations/?cr=${report.id}`;
                                    break;

                                case 'SensorHistory':
                                    link = `/reports/tag-property/?cr=${report.id}`;
                                    break;
                                case 'LocationHistory':
                                    link = `/reports/tracking/?cr=${report.id}`;
                                    break;
                                default:
                                    console.error('Unknown report groupKey:', report.groupKey);
                                    break;
                            }
                            return {
                                id: report.id.toString(), // Convert Guid to string
                                title: report.name,
                                icon: report.icon ?? 'fal:file-chart-column',
                                link: link,
                                type: 'basic'
                            };
                        })
                };

                if (!reportsMenu.children) {
                    reportsMenu.children = [];
                }

                reportsMenu.children.push(customReportsMenu);
                this.navComponent.refresh();
            }
        });
    }
}
