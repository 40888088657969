import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomEventService {
    private subjects: Subject<any>[] = [];

    publish(eventName: any): void {

        this.subjects[eventName] = this.subjects[eventName] || new Subject<any>();
        this.subjects[eventName].next(null);

    }

    on(eventName: any): Observable<any> {
        this.subjects[eventName] =
            this.subjects[eventName] || new Subject<any>();
        return this.subjects[eventName].asObservable();
    }

}
