
import { NgModule } from '@angular/core';
import { DxDataGridModule, DxDateBoxModule, DxDropDownBoxModule, DxLookupModule, DxSelectBoxComponent, DxSelectBoxModule, DxTextBoxModule, DxTreeListModule, DxTreeViewModule } from 'devextreme-angular';

const devexpresComponents = [

    DxTreeListModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxDataGridModule,
    DxTextBoxModule,
    DxLookupModule,
    DxDateBoxModule,
    DxSelectBoxModule

];

@NgModule({
    imports: [...devexpresComponents],
    exports: [...devexpresComponents],

})
export class DevExtremesModule { }
