<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->


<fuse-vertical-navigation [class.dark]="true" class="backdrop-blur-md bg-black/40 print:hidden "
    [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation.default"
    [opened]="!isScreenSmall" [inner]="false">


    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>

        <div class="flex items-center w-full p-2">

            <div class="flex items-center ml-auto">
                <user-profile [showAvatar]="false"></user-profile>
            </div>

        </div>

        <div class="flex flex-col items-center w-full mb-4">

            <div class="w-36 h-36 rounded-lg bg-white/60 flex items-center justify-center">
                <app-navizard-logo [usePreset]="true" [isLight]="false" [logoType]="'v1'"></app-navizard-logo>
            </div>

            <div class="flex flex-col items-center justify-center w-full mt-2">

                <div
                    class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden text-center leading-normal font-mediu">
                    {{displayName}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap overflow-ellipsis overflow-hidden text-center text-md leading-normal font-medium ">
                    {{userName}}
                </div>

            </div>

        </div>
    </ng-container>

    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>

        <div class="flex flex-0 items-center justify-center h-64 pr-6 pl-2 mt-2 mb-4 opacity-12">

            <app-navizard-logo class="w-36 h-36" [usePreset]="true" [isLight]="true"
                [logoType]="'v1'"></app-navizard-logo>
        </div>

    </ng-container>

    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationFooter>

        <div
            class="p-2 text-xs text-center justify-center font-semibold text-gray-600 dark:text-gray-300 bg-transparent">
            <app-navizard-versioning></app-navizard-versioning>
        </div>
    </ng-container>

</fuse-vertical-navigation>


<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 overflow-hidden relative">

    <div class="absolute inset-0 safe-area">
        <!-- DEBUG Badge, eğer debug modu aktifse -->
        <div *ngIf="globals.isDebugMode" class="ribbon up scale-[0.7] md:scale-100" style="--color: #b71f17;">
            <div class="content">
                <a [routerLink]="['settings']" fragment="Performance-Browser-EnableDebug">
                    <div>DEBUG<br>MODE</div>
                </a>
            </div>
        </div>
        <div
            class="frosty flex items-center h-16 px-1 mt-3 ml-3 mr-3 mx-auto top-0 z-10 print:hidden absolute inset-0 ">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <fa-icon [icon]="['fal', 'bars']" class="text-2xl"></fa-icon>
            </button>
            <!-- Components -->
            <div class="flex-grow"></div> <!-- Bu div sol taraftaki boşluğu dolduracak -->
            <div class="flex flex-col items-center space-y-1">
      
                <div *ngIf="!(networkStatusService.isOnline | async)"
                    class="text-center bg-white/70 ring-2 ring-white/10 border-none border-white/20 text-red-600 p-2 rounded-md  px-12 py-3 sm:py-1">
                    <div class="text-md text-red-500 ">
                        ÇEVRİMDIŞI
                    </div>
                    <div class="text-sm text-red-500 hidden sm:block">
                        Şu anda çevrimdışısınız. Görüntülenen bilgiler güncel olmayabilir.
                    </div>
                </div>

            </div>
            <div class="flex-grow"></div> <!-- Bu div sağ taraftaki boşluğu dolduracak -->
            <div class="flex items-center space-x-0.5 sm:space-x-2">

                <button mat-icon-button *ngrxLet="(notificationData$ | async)?.unreadMessages as unreadCount"
                    #notificationsOrigin routerLinkActive routerLink="/notifications" [matTooltip]="'Notifications'">
                    <ng-container>
                        <!-- Sadece unreadCount 0'dan büyükse sayıyı göster -->
                        <span *ngIf="unreadCount > 0"
                            class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
                            <span
                                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-500 z-10 text-indigo-50 text-xs font-medium">
                                {{ unreadCount }}
                            </span>
                        </span>
                    </ng-container>
                    <!-- Zil ikonu her zaman görünür, ama yalnızca unreadCount 0'dan büyükse sallanır -->
                    <fa-icon [icon]="[unreadCount > 0?'fas':'fal', 'bell']" [class.fa-shake]="unreadCount > 0"
                        class="block z-0" size="lg" style="--fa-animation-duration: 4s;"></fa-icon>
                </button>



                <!-- <button mat-icon-button #notificationsOrigin routerLinkActive routerLink="/notifications"
                    [matTooltip]="'Notifications'">
                    <ng-container *ngIf="(notificationData$ | async)?.unreadMessages as unreadCount">
                        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
                            <span
                                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-500 text-indigo-50 text-xs font-medium">
                                {{unreadCount}}
                            </span>
                        </span>
                    </ng-container>
                    <fa-icon [icon]="['fal' , 'bell']"   class="block" size="lg" style="--fa-animation-duration: 4s;"></fa-icon>
                </button> -->

                <button mat-icon-button #bgorigin (click)="backgroundService.setBackground()"
                    [matTooltip]="'Live Tracking'">
                    <fa-icon [icon]="['fal' , 'image']" size="lg"></fa-icon>
                </button>


                <button mat-icon-button #shortcutsOrigin routerLinkActive routerLink="/live"
                    [matTooltip]="'Live Tracking'">
                    <fa-icon [icon]="['fal' , 'map-marked']" size="lg"></fa-icon>
                </button>


                <languages></languages>
                <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            </div>
        </div>
    </div>
    <!-- header -->


    <!-- Content -->

    <!-- <ng-scrollbar [visibility]="'hover'" >  -->
    <div class="absolute inset-0 overflow-y-auto overflow-x-hidden ">
        <div class="">
            <div class="w-full  ">
                <router-outlet> </router-outlet>
            </div>
        </div>

    </div>
    <!-- </ng-scrollbar>  -->

</div>