
import packageInfo from '../../package.json';
// environment.base.ts
export const baseEnvironment = {
    version: packageInfo.version,
    copyright: 'Bakelor RTLS Navizard Copyright Reserved',
    
    firebaseConfig: {
      apiKey: "AIzaSyB-UJhrysgoDFPYh7Tdui-AVQfMGXseCqw",
      authDomain: "ab-rtls-pn.firebaseapp.com",
      projectId: "ab-rtls-pn",
      storageBucket: "ab-rtls-pn.appspot.com",
      messagingSenderId: "921218140601",
      appId: "1:921218140601:web:9911ffbedef0322b79ce68"
    }
  };
  