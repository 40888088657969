import { baseEnvironment } from "./environment.base";

export const environment = {
    ...baseEnvironment,
    production: true,
    pageNo: 1,
    pageSize: 50,
    mapCenter: [41.006506, 29.246758],
    api: `${location.protocol}//${location.hostname}${location.port ? ':' + location.port + '/api/v1' : '/api/v1'}`,
    apiv2: `${location.protocol}//${location.hostname}${location.port ? ':' + location.port + '/api/v2' : '/api/v2'}`,
    tile: `${location.protocol}//${location.port ? location.hostname + ':' + location.port : location.hostname}`,
  
    imagePath: 'http://navizard.pinock.com:10001/content/images',
    site: `${location.protocol}/${location.port ? location.hostname + ':' + location.port : location.hostname}`,
};
