import { Injectable } from '@angular/core';
import { BaseHubConnection } from './base-hub-connection.service';
import { TagAlert } from './models/tag-alert.model';
import { BehaviorSubject } from 'rxjs';
import { GlobalVariablesService } from '../global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class AlertHubConnectionService extends BaseHubConnection{
 
  private alertUpdatedSubject: BehaviorSubject<TagAlert> = new BehaviorSubject<TagAlert>(null);
  public alertUpdated$ = this.alertUpdatedSubject.asObservable();

  constructor(private globals: GlobalVariablesService) {
    super();
  }

  public init() {
    this.initializeHubConnection(`${this.globals.tracker.address}/alert-hub`);
    this.connectionStatus.subscribe((status) => {
      console.log('AlertHubConnectionService status:', status);
    }
    );

    this.hubConnection.on("AlertUpdated", (data: TagAlert) => {
      const tagAlert = new TagAlert(data);
      this.alertUpdatedSubject.next(tagAlert);
    });
  }



}
