/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Router } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen, catchError, take } from 'rxjs/operators';
import { BadisOauthLoginService } from '../badis-oauth-login.service';
import { GlobalVariablesService } from '../../global-variables.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

    private requests: HttpRequest<any>[] = [];

    private delayInMinutesBetweenRetries = 1000;
    private numberOfRetries = 3;
    private authorizationHeader = 'Authorization';

    constructor(
        //  @Optional() @Inject(APP_CONFIG) private _appConfig,
        private inj: Injector,
        private router: Router,
        private badisOauthLoginService: BadisOauthLoginService,
        private global: GlobalVariablesService

    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const userAccessToken = this.badisOauthLoginService?.userAccessToken;
       

        if (userAccessToken?.accessToken) {

            const token = userAccessToken.accessToken;
            if (this.global.app) {
                req = req.clone({
                    setHeaders: {
                        'Authorization': `Bearer ${token}`,
                        //'Content-Type': 'application/json; charset=utf-8; multipart/form-data; application/zip; application/octet-stream',
                        //'Accept': 'application/json; application/octet-stream; application/zip',
                       // 'Enctype': 'multipart/form-data',
   
                        'TenantId': this.global.app.tenantId,
                        'CompanyId': userAccessToken.companyId,
                    }
                });
            }

            this.requests.push(req);

            return next.handle(req)
                .pipe(
                    retryWhen(errors => errors.pipe(
                        mergeMap((error: HttpErrorResponse, retryAttempt: number) => {

                            if (retryAttempt === this.numberOfRetries - 1) {
                                console.log(`HTTP call '${req.method} ${req.url}' failed after ${this.numberOfRetries} retries.`);
                                return throwError(error); // no retry
                            }

                            switch (error.status) {
                                case 400:
                                case 404:
                                    return throwError(error); // no retry
                                case 419:
                                    break;
                            }

                            // this.removeRequest(req);
                            return of(error); // retry

                        }),

                        delay(this.delayInMinutesBetweenRetries),
                        take(this.numberOfRetries)

                    )),
                    catchError((error: any, caught: Observable<HttpEvent<any>>) => {

                        console.error({ error, caught });

                        if (error.status === 403) {
                            this.router.navigate(['/accessdenied?2']);
                        }

                        if (error.status === 401) {

                            const newRequest = this.getNewAuthRequest(req);
                            if (newRequest) {
                                return next.handle(newRequest);
                            }

                            this.router.navigate(['/auth']);

                        }
                        return throwError(error);
                    })
                );
        } else {

            //    this.router.navigate(['/auth']);
            return next.handle(req);

        }
    }

    protected getNewAuthRequest(request: HttpRequest<any>): HttpRequest<any> | null {

        const newStoredToken = this.badisOauthLoginService.userAccessToken.accessToken;

        const requestAccessTokenHeader = request.headers.get(this.authorizationHeader);

        if (!newStoredToken || !requestAccessTokenHeader) {
            console.log('There is no new AccessToken.', { requestAccessTokenHeader: requestAccessTokenHeader, newStoredToken: newStoredToken });
            return null;
        }

        const newAccessTokenHeader = `Bearer ${newStoredToken}`;

        if (requestAccessTokenHeader === newAccessTokenHeader) {
            console.log('There is no new AccessToken.', { requestAccessTokenHeader: requestAccessTokenHeader, newAccessTokenHeader: newAccessTokenHeader });
            return null;
        }

        return request.clone({ headers: request.headers.set(this.authorizationHeader, newAccessTokenHeader) });
    }

}

