import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PasswordValidatorService {

  constructor() { }

  // Password strength validator
  public passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;  // Eğer değer boşsa kontrol yapma
      }

      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumeric = /[0-9]/.test(value);
      const hasValidLength = value.length >= 8;

      const valid = hasUpperCase && hasLowerCase && hasNumeric && hasValidLength;
      if (!valid) {
        // Genel hata mesajını döndür
        return { 
          passwordStrength: 'Password must be at least 8 characters long, include uppercase and lowercase letters, and contain at least one number.' 
        };
      }
      return null;
    };
  }
}
