
export enum OperationType {
    create = 0,
    update = 1,
    delete = 2
}
export class OperationResult<T> {
    public operationType: OperationType;
    public model: T;
    public success: boolean;
}
