<svg class="max-w-full max-h-full object-contain" version="1.1" id="navizardLogo" #navizardLogo
	xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 1024 1024" xml:space="preserve">
	
	<g *ngIf="logoType=='v1'" >
		<path id="LogoPartLeft" d="M526.5,877.6c-38.2,0.6-75.7,0.2-113.3,0.2c-36.8,0-73.6,0.1-110.4,0.2
			c-17.6,0.1-35.2,0.1-52.8-0.7c-27.2-1.3-49.9-13.2-69-32.2c-21.8-21.7-33-48.2-33.2-79.1c-1.7-157.8-0.4-315.6-0.6-473.3
			c0-15.4-0.7-30.7,0.6-46.1c3.6-44.5,35.2-83.2,78.7-95.9c10.4-3,21-4.7,31.8-4.7c108-0.1,216-0.6,324,0.2c5.3,0,10.6,0.5,15.6,0.7
			c0.2,3.5-1.8,3.7-3.4,4.2c-34.4,10-65.9,25.4-91.9,50.8c-2.8,2.6-5.9,2.2-9,2.2c-76.2,0-152.4,0-228.6,0.1
			c-27.6,0-50.3,17.2-58,43.4c-2.2,7.6-2.2,15.4-2.2,23.2c0,111.2,0,222.4,0,333.6c0,51.2-0.1,102.4-0.1,153.6
			c0,26.8,16,50.5,40,59.2c6.2,2.3,12.8,3,19.4,3c165.6,0,331.2,0.1,496.8-0.1c33.5,0,58.6-31.1,58.3-59.9
			c-0.6-50.4-0.1-100.8-0.1-151.2c0-23.2,0-46.4,0-69.6c0-8-0.8-8.8-9-8.8c-6.6,0-13.1,0-19.2,0c-1.7-3.2,0-5,0.8-7
			c13.8-32.8,29-64.9,42.1-98c12.8-32.2,16.4-65.2,13.1-99.4c-3.7-37.7-18.2-71.3-41.6-100.4c-29.3-36.5-67.3-60.1-112.7-72.2
			c-4.2-1.1-8.5-2.3-12.7-3.5c-0.5-0.1-0.8-0.7-1.3-1.2c1.4-2.8,4.1-2.2,6.4-2.2c26.8-0.4,53.5-0.8,80.4-1
			c27.1-0.1,50.8,9.5,71.5,26.4c21.2,17.3,33.8,39.6,38.6,66.4c1,5.6,1.3,11.5,1.3,17.3c0.1,170,0.1,340,0.1,510
			c0,53.9-41.4,102.1-94.3,110.3c-4.6,0.7-9.1,1.1-13.7,1.1c-40.8,0-81.6,0-122.4,0.1c-25.8,0.1-51.6,0.5-77.4,0.5
			C555.1,878.2,541.1,877.8,526.5,877.6z" />
		<path id="Lightning" d="M475.7,375.5c-4.8-32.8-0.5-64.1,13.3-93.4c24.8-52.6,66.2-85.6,123.6-95c70.7-11.6,127.8,13.3,169.7,71
			c16.8,23,26,49.7,28.9,78.4c3.2,33.6-5.2,64.6-19.1,94.6c-29.6,64.2-59.3,128.4-88.9,192.7c-18.1,39.2-36.1,78.4-54.2,117.6
			c-0.2,0.5-0.7,1-1,1.3c-1.8,0.4-2.2-0.8-2.6-1.9c-20.4-45.2-43.1-89.3-65-133.7c-29.3-59.3-58.4-118.8-87.4-178.2
			C484.7,412.1,478.8,394.4,475.7,375.5 M540.9,317.3c-6.7,20.9-7.8,41.9-1.2,63c16.6,52.8,67.1,84.6,124.1,73.3
			c60.7-11.9,96-69.7,82-129.4c-10-42.1-44.3-72.7-87.4-79.3C606,236.9,556.8,266.9,540.9,317.3 M654.3,535.7c0-6.6,0-13.2-0.1-19.8
			c0-1.4,0.1-3-1.4-3.7c-1.8-0.8-3,0.4-4.1,1.6c-3.7,4.4-7.4,8.8-11.3,13.2c-10.6,12-20.2,24.7-31.2,36.4c-1.9,2-4.9,4.4-3.4,7.7
			c1.4,3.1,4.9,2,7.6,2.2c7,0.1,13.9,0.1,21,0.1c7.7,0,7.8,0.2,7.9,7.6c0,10.2,0,20.4,0.1,30.6c0,2-0.8,4.4,1.6,5.9
			c2.3,0.2,3.2-1.4,4.4-2.6c15.6-16.3,28.8-34.7,44-51.4c1.1-1.2,2.3-2.4,1.6-4.2c-0.8-2-2.8-2-4.6-2c-8.6,0-17.2-0.1-25.8,0
			c-4.4,0.1-6.7-1.4-6.5-6.2C654.6,546.1,654.3,541.4,654.3,535.7z" />
		<path id="LogoPartRight" d="M270.7,339.5c0-8.6,0-16.7,0-24.7c0.7-0.2,1.4-0.5,2.3-0.6c70,80.9,139.9,161.9,209.9,242.8
			c0.7-0.1,1.3-0.4,2-0.5c0-17.5,0-34.9,0-52.4c0.7-0.1,1.4-0.2,2.2-0.4C527,584,567,664.6,607.5,746.4c-8.5,0-15.7-0.6-22.7,0.1
			c-10.3,1-17.2-2.8-24-10.7c-46.3-53.5-93.1-106.6-139.8-159.7c-27.8-31.7-55.4-63.5-83.3-95.3c-1.3-1.6-2.3-4-5.3-3.5
			c-2.4,2.5-1.3,5.6-1.3,8.5c-0.1,83.5-0.1,167.2-0.1,250.7c0,10,0,10-10.1,10c-14.4,0-28.8-0.1-43.2,0.1c-5.8,0.1-7.6-1.7-7.6-7.6
			c0.4-46.8,0.5-93.6,0.5-140.4C270.8,512.4,270.7,426.2,270.7,339.5z" />
	</g>

	<g *ngIf="logoType=='v2'" >
		<path id="Lightning" class="st0" d="M551,583c-48.2,47.3-96.1,94.4-143.9,141.4c-9,8.7-18.3,9.2-26.3,1.3
			c-7.9-8.1-7.8-19.2,0.8-27.7c27-26.8,54.3-53.6,81.4-80.3c28.6-28.3,57.2-56.8,85.8-85.2c5.1-5.1,10.4-9.9,15.5-14.8
			c1.7-1.6,4-2.9,3.6-5.8c-2.3-2.2-5.3-1.3-7.9-1.3c-41.9-0.1-83.6-0.1-125.4-0.3c-5.6,0-11.2-0.1-16.8-0.3
			c-8.2-0.3-14-4.3-17.2-11.8c-3.1-7.9-1.8-15.1,4.4-21.3c37.8-37.8,75.7-75.7,113.5-113.5c33.8-33.9,67.6-67.7,101.4-101.7
			c7-7,18.1-7.2,25.6-0.1c7.8,7.1,8.5,17.5,0.9,25.3c-10.1,10.7-20.8,20.9-31.2,31.3c-50,50.2-100.1,100.2-150,150.4
			c-1,1-2.3,2-2.5,3.6c2.1,1.8,4.6,1,6.9,1c42.9,0,85.8,0.1,128.7,0c5.5,0,10.8-0.6,16.2-0.6c9.1,0,16.6,3.1,20.3,12.2
			c3.5,8.7-0.1,15.6-6.1,21.6C602.8,532,577.1,557.3,551,583L551,583z" />
		<path id="LogoPartRight" class="st0" d="M810.8,149.8c1.2,4.6-0.3,8.2-2.2,11.7c-27.6,50.3-53.2,101.7-79.3,152.8
			c-10.1,19.9-20.5,39.6-30.9,59.3c-2,3.5-1.8,6.1,0.7,9.4c8.5,11.8,16.6,23.8,25,35.6c5.1,7.1,11,8.4,17.2,4.3
			c6.6-4.6,7.8-11.6,3.1-18.7c-4.9-7.5-10.1-15.1-15.3-22.5c-2.2-3-2.3-5.5-0.4-8.8c5.9-9.9,11.2-19.9,16.5-29.5c3-0.3,3.5,1.7,4.4,3
			c25.1,36.3,37.4,76.8,41.5,120.6c5.2,56.6-7.5,108.9-32.8,159.1c-18.7,37.1-44.3,69.2-71.1,100.4c-39.8,46.4-84.4,88.3-127.7,131.3
			c-6.2,6.2-12.7,12.4-19.4,18.2c-14.7,13.1-30.2,13.1-44.7-0.1c-27.8-25.4-54.7-51.9-81.2-78.7c-6-6-11.8-12.1-17.9-17.9
			c-3.1-3-3.6-5.2-0.1-8.5c31.3-29.9,62.7-59.8,93.9-89.8c2.7-2.7,5.7-3.4,9.4-3.2c23.1,0.7,46.3-0.3,68.8-6.6
			c56.8-16,95.5-51.6,118.3-106c12.4-29.5,14.2-59.9,12.4-91s-13.5-58.9-32.4-83.5c-6.9-9-9.9-15.9-3.4-27.4
			c14.2-25.4,26-51.9,38.7-77.9c1.3-2.7,3.2-5.2,3.5-8.3c-1.8-1.8-3.2-0.3-4.7,0.4c-10,4.3-18.7,2.5-23.8-4.9c-4.7-7-6-17.7,3.2-26.3
			c32.9-30.8,64.5-62.9,96.3-94.8c2.1-2.1,4.3-4.3,6.4-6.5C792.6,134.6,803.6,136.3,810.8,149.8L810.8,149.8z" />
		<path id="LogoPartLeft" class="st0"
			d="M383.4,366c-21.1,23.4-37.3,49.3-43.9,79.7c-10.4,47.1-8.2,92.9,18.1,135.3
			c4.5,7.4,9.5,14.6,15.7,20.5c3.4,3.2,3.8,5.9,1.6,10c-16.4,30.3-32.4,60.7-48.6,91.1c-1.2,2.3-2.6,4.4-2.6,7.8
			c6.4-2.5,12.6-4.7,19.2-1.7c13.4,6.1,16,19.5,5.7,30c-17.5,17.9-35.1,35.9-52.6,53.8c-18.5,18.9-36.8,37.8-55.4,56.7
			c-7.8,7.9-17.3,7.7-24.2-0.7c-4.4-5.3-4.8-11-1-17.4c32.1-56.4,61.4-114.4,92.4-171.5c8.8-16.4,17.3-33,26-49.4
			c1.8-3.2,1.7-5.7-0.5-8.8c-7.7-10.5-14.8-21.3-22.4-32c-5.1-7.2-11.8-9.2-17.7-5.7c-6.1,3.8-7.5,11.2-3,18.8
			c3.8,6.4,8.2,12.2,12.3,18.3c1.3,2,3.1,3.8,1.8,6.5c-5.6,11.2-11.2,22.4-18.1,32.6c-4-0.7-4.8-3.6-6.1-5.8
			c-19.8-34.2-32.5-70.5-36.5-110c-1.3-12.7-2.9-25.4-2.3-38.1c2.2-59.7,18.6-114.3,55.5-162.2c37.6-48.8,85.9-81.8,144.4-100
			c20-6.2,40.6-9.6,61.6-10.8c35-2.1,68.9,2.6,101.9,14c1.8,0.7,3.6,1.3,5.5,2.2c0.8,0.4,1.3,1,1.8,1.6c0.3,1.8-0.6,2.7-1.6,3.6
			c-24.7,23.9-49.4,48-74,72c-2.7,2.6-5.3,3.8-9.2,3.5c-28.1-2.1-55.1,2.5-81.1,13.1C422.1,332.9,401.5,347.2,383.4,366L383.4,366z" />
	</g>

</svg>