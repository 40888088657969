<button mat-icon-button [matMenuTriggerFor]="languages">
    <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: languageService.selectedLanguage}"></ng-container>
</button>

<mat-menu [xPosition]="'before'" #languages="matMenu">
    <ng-container *ngFor="let lang of languageService.availableLanguages; let i=index">
        <button mat-menu-item (click)="languageService.selectedLanguage=lang">
            <span class="flex items-center">
                <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: lang}"></ng-container>
                <span class="ml-3">{{lang.title}}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>

<ng-template let-lang #flagImage>
    <span class="relative w-6 shadow rounded-sm overflow-hidden">
        <span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
    <img class="w-full" [src]="lang.flag" [alt]="'Flag image for ' + lang.title">
    </span>
</ng-template>
