
import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

export abstract class BaseHubConnection {
  protected hubConnection: HubConnection;
  public connectionStatus: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public connectionError: BehaviorSubject<Error> = new BehaviorSubject<Error>(null);

  constructor() {

  }

  public initializeHubConnection(hubUrl: string) {
    const retryDurations = Array(500).fill(60000); // 500 attempts to reconnect every 60 seconds
    retryDurations.unshift(1000); // First attempt after 1 second
    retryDurations.push(5000); // Last attempt after 5 seconds
    retryDurations.push(10000); // Last attempt after 10 seconds
    retryDurations.push(30000); // Last attempt after 30 seconds
    retryDurations.push(60000); // Last attempt after 60 seconds


    this.hubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Error)
      .withUrl(hubUrl, {
        logMessageContent: true,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets ||
          HttpTransportType.LongPolling ||
          HttpTransportType.ServerSentEvents
      })
      .withAutomaticReconnect(retryDurations)
      
      .build();

    this.hubConnection.onreconnecting(error => {
      this.connectionStatus.next(false);
      this.connectionError.next(error);
    });

    this.hubConnection.onreconnected(() => {
      this.connectionStatus.next(true);
    });

    this.hubConnection.onclose(error => {
      this.connectionStatus.next(false);
      if (error) {
        this.connectionError.next(error);
      }
    });


  }

  public startConnection() {
    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started');
        this.connectionStatus.next(true);
      })
      .catch(error => {
       // console.error('Error while starting connection:', error);
        this.connectionStatus.next(false);
        this.connectionError.next(error);
      });
  }

  public sendMessage(methodName: string, ...args: any[]) {
    this.hubConnection.invoke(methodName, ...args).catch(err => {
      console.error('Error while sending message:', err);
      this.connectionError.next(err);
    });
  }

  public onMessage(methodName: string, callback: (...args: any[]) => void) {
    this.hubConnection.on(methodName, callback);
  }

  public onEvent(eventName: string, callback: (...args: any[]) => void) {
    this.hubConnection.on(eventName, callback);
  }

  public disconnect() {
    this.hubConnection.stop().catch(err => {
      console.error('Error while disconnecting:', err);
      this.connectionError.next(err);
    });
    this.connectionStatus.next(false);
  }

  public get currentState(): HubConnectionState {
    return this.hubConnection.state;
  }
}
