import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostButtonExecutingDirective } from './post-button-executing.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PostButtonExecutingDirective
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    TranslateModule,
  ],
  exports:[PostButtonExecutingDirective]
})
export class PostButtonExecutingModule { }
