/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';

import { compactNavigation, defaultNavigation, horizontalNavigation } from 'app/mock-api/common/navigation/data';

import { BadisOauthLoginService } from '@app/core/services/auth/badis-oauth-login.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationMockApi {

    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    constructor(
        private _fuseMockApiService: FuseMockApiService,
        private _badisAuthService: BadisOauthLoginService
    ) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactNavigation.forEach((compactNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === compactNavItem.id) {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === horizontalNavItem.id) {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact: cloneDeep(this._compactNavigation),
                        default: cloneDeep(this._defaultNavigation),
                        horizontal: cloneDeep(this._horizontalNavigation)
                    }
                ];
            });
    }
    /*
    registerHandlers(): void {

        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() => {

                this._compactNavigation.forEach((compactNavItem) => {

                    this._defaultNavigation.forEach((defaultNavItem) => {

                        if (defaultNavItem.id === compactNavItem.id) {

                            compactNavItem.children = cloneDeep(
                                defaultNavItem.children,
                            );

                        }

                    });
                });

                return [
                    200,
                    {
                        compact: cloneDeep(this._compactNavigation),
                        default: cloneDeep(this._defaultNavigation),
                    },
                ];

            });

    }*/

}
