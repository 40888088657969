import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { SecurityAlert, SecurityAlert as SecurityContext } from './models/security-alert';
import { ImageService } from '@app/shared/services/image.service';

@Component({
  selector: 'app-security-alert',
  templateUrl: './security-alert.component.html',
  styleUrls: ['./security-alert.component.scss']
})
export class SecurityAlertComponent extends Toast implements OnInit {

  safeHtml: SafeHtml;
  alert: SecurityAlert;
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage, protected sanitizer: DomSanitizer,
    public imageService: ImageService) {
    super(toastrService, toastPackage);

    this.alert = JSON.parse(this.message);

    this.safeHtml = sanitizer.bypassSecurityTrustHtml(this.alert.content);
  }

  ngOnInit(): void {

  }

}
