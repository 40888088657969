import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPostResponse } from '@app/core/interfaces/IPostResponse';
import { environment } from '@env/environment';
import { of, ReplaySubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap, finalize } from 'rxjs/operators';
import { BrowserStorageService } from '../storage/browser-storage.service';
import { JwtHelperService } from './jwt-helper.service';
import { UserAccessToken } from './model/auth-user.interface';
import { GlobalVariablesService } from '../global-variables.service';
import { Guid } from '@app/core/constants/constants';

@Injectable({ providedIn: 'root' })
export class BadisOauthLoginService {
    private _userAccessToken: UserAccessToken;
    private tokenLoaded: boolean = false;

    public get userAccessToken(): UserAccessToken {
        if (!this.tokenLoaded) {
            const token = this.loadLoginToken();
            if (token && !this.jwtHelperService.isTokenExpired(token.accessToken)) {
                this._userAccessToken = token;
            }
            this.tokenLoaded = true;
        }
        return this._userAccessToken;
    }

    public set userAccessToken(value: UserAccessToken) {
        this._userAccessToken = Object.freeze(value);
        this.storeLoginToken(value);
        this.tokenLoaded = true;
    }

    public authStatusSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public onAuthStatusChanged = this.authStatusSubject.asObservable();

    private refreshSub: Subscription;

    constructor(
        private router: Router,
        private http: HttpClient,
        private jwtHelperService: JwtHelperService,
        private browserStorageService: BrowserStorageService,
        private route: ActivatedRoute, private globals: GlobalVariablesService
    ) {
        this.updateStatusOnPageRefresh();
    }

    private invokeAuthStatusChanged(isAuthenticated: boolean): void {
        this.authStatusSubject.next(isAuthenticated);
    }

    private headerSetter(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
        });
    }

    public loginUser(loginModel: any): any {
        return this.http
            .post(`${this.getBaseUrl()}/account/login`, JSON.stringify(loginModel), { headers: this.headerSetter() })
            .pipe(
                map((response: IPostResponse) => {
                    if (!response.success) {
                        return response;
                    }
                    this.processUserLoginToken(response.token);
                    this.scheduleTokenRefresh();
                    this.invokeAuthStatusChanged(true);
                    return response;
                }),
                catchError((error: HttpErrorResponse) => {
                    this.invokeAuthStatusChanged(false);
                    return throwError(() => new Error(error.message));
                })
            );
    }

    public isUserLoggedIn(): boolean {
        const token = this.userAccessToken;
        return !!token && !this.jwtHelperService.isTokenExpired(token.accessToken);
    }

    public logout(): void {
        const token: UserAccessToken = this.userAccessToken;
        if (token) {
            this.http
                .post<IPostResponse>(`${this.getBaseUrl()}/account/logout`, { refreshToken: token.refreshToken }, { headers: this.headerSetter() })
                .pipe(finalize(() => this.clearSession()))
                .subscribe();
        } else {
            this.clearSession();
        }
    }

    private clearSession() {
        this.browserStorageService.removeLocal('badis_oauth_token');
        this.unscheduleRefreshToken();
        this._userAccessToken = null;
        this.tokenLoaded = false;
        this.invokeAuthStatusChanged(false);
        this.router.navigate(['/auth']);
    }

    private getBaseUrl(): string {
        return environment.api;
    }

    private processUserLoginToken(token: string): void {
        const jwtData: any = this.jwtHelperService.decodeToken(token);
        this.userAccessToken = {
            accessToken: token,
            refreshToken: jwtData.refreshToken,
            expires: jwtData.exp,
            avatar: jwtData.avatar,
            defaultLink: jwtData.defaultLink,
            userName: jwtData.userName,
            userId: jwtData.userId,
            roles: jwtData.roles.split(','),
            displayName: jwtData.displayName,
            dynamicUserMenuClaims: jwtData.dynamicUserMenuClaims,
            sessionName: jwtData.sessionName,
        };
        this.storeLoginToken(this.userAccessToken);
    }

    private loadLoginToken(): UserAccessToken {
        return this.browserStorageService.getLocal<UserAccessToken>('badis_oauth_token');
    }

    private storeLoginToken(token: UserAccessToken): void {
        this.browserStorageService.setLocal('badis_oauth_token', token);
    }

    private unscheduleRefreshToken(): void {
        if (this.refreshSub) {
            this.refreshSub.unsubscribe();
        }
    }

    private updateStatusOnPageRefresh(): void {
        const isAuthenticated = this.isUserLoggedIn();
        this.invokeAuthStatusChanged(isAuthenticated);
    }

    private scheduleTokenRefresh(): void {
        const token = this.userAccessToken;
        if (token) {
            const expiresAt = new Date(0);
            expiresAt.setUTCSeconds(token.expires);
            const now = new Date();
            const delay = expiresAt.getTime() - now.getTime() - (120 * 1000); // Refresh 2 minutes before expiry

            this.refreshSub = timer(delay).subscribe(() => this.refreshToken());
        }
    }

    private refreshToken(): void {
        const token = this.userAccessToken;
        if (token) {
            this.http
                .post(`${this.getBaseUrl()}/account/refresh-token`, { refreshToken: token.refreshToken }, { headers: this.headerSetter() })
                .pipe(
                    map((response: any) => {
                        this.processUserLoginToken(response.token);
                        this.scheduleTokenRefresh();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.clearSession();
                        return throwError(() => new Error(error.message));
                    })
                )
                .subscribe();
        }
    }

    public resetPassword(userModel: any): any {
        const url: string = `${this.getBaseUrl()}/users/change-password`;
        return this.http
            .put(url, userModel, { headers: this.headerSetter() })
            .pipe(
                map((response: any) => response),
                catchError((error: HttpErrorResponse) => throwError(error))
            );
    }

    public forgotPassword(email: string): any {
        return this.http
            .post(`${this.getBaseUrl()}/users/forgot-password`, { email: email }, { headers: this.headerSetter() })
            .pipe(
                map((response: any) => response),
                catchError((error: HttpErrorResponse) => throwError(error))
            );
    }

    public isUserInRoles(requiredRoles: string[]): boolean {
        const user = this.userAccessToken;

        if (!user || !Array.isArray(user.roles)) {
            return false;
        }

        const hasRequiredRole = requiredRoles.some(role => user.roles.includes(role));
        return hasRequiredRole;
    }



    // search user role in globals.navizardSystemMenu
    public isAuthenticatedMenu(menuId: string | Guid): boolean {

        // if menuId is null or empty, return true
        if (!menuId) {
            return true;
        }

        if (!this.globals.navizardSystemMenu) {
            return false;
        }

        // also search in children and return found object
        const found = this.findMenu(this.globals.navizardSystemMenu, menuId);
        if (found) {
            // user roles exists in menu roles, also check claims
            if (found.roles && found.roles.length > 0 && this.isUserInRoles(found.roles)) {
                return true;
            }
        }

        // dynamicUserMenuClaims'i bir diziye dönüştür
        const claims = Array.isArray(this._userAccessToken.dynamicUserMenuClaims)
            ? this._userAccessToken.dynamicUserMenuClaims
            : [this._userAccessToken.dynamicUserMenuClaims];

        // claimId'leri döngü ile kontrol et
        for (const claimId of claims) {
            if (claimId === menuId) {
                return true;
            }
        }
        return false;

    }

    findMenu(dynamicUserMenuClaims: any, menuId: string | Guid) {

        for (let i = 0; i < dynamicUserMenuClaims.length; i++) {
            if (dynamicUserMenuClaims[i].id === menuId) {
                return dynamicUserMenuClaims[i];
            }
            if (dynamicUserMenuClaims[i].children) {
                const found = this.findMenu(dynamicUserMenuClaims[i].children, menuId);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }





}
